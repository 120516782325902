.broadcast-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .customer-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;

    .customer-label {
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.44);
    }
  }

  .contact-rows {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    min-height: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .show-more-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
  }
}
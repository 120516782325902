.navigation-tab-list {
  display: flex;
  position: relative;
  top: -2px;
  width: 100%;
  
  &.content-navigation {
    .navigation-tab {
      .navigation-tab-button {
        .navigation-display {
          .text {
          }
        }
      }
    }
  }

  &.assistant-navigation {
    .navigation-tab {
      .navigation-tab-button {
        .navigation-display {
          .text {
            width: 115px;
          }
        }
      }
    }
  }

  .navigation-tab {
    border-right: none;
    flex: 1;

    &:last-child {
      border-right: 2px solid #000000;
    }
  }
}
.unavailable-chat-history {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .unavailable-chat-history-text {
    text-align: center;
    width: 300px;
    font-weight: 600;
  }
}

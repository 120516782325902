.search-input-container {
  display: flex;
  align-items: center;
  background: #EAE8FA;
  padding: 10px 15px;
  border-radius: 20px;

  .search-input {
    outline: none;
    border: none;
    background: transparent;
    width: 180px;
    font: 400 14px Krub;
  }

  .search-icon {
    width: 16px;
  }
}
.template-welcome-section {
  display: flex;
  flex-flow: column;

  .title-template-input {
    position: relative;
    top: -2px;
  }

  .template-description-section {
    margin-top: 18px;

    .description-template-input {
      background: #F4F5F5;
      
      &.template-text-input-container {
        .text {
          .template-text-input {
            min-height: 30px;
          }
        }
      }
    }
  }

  .template-welcome-details {
    margin-top: 25px;

    .welcome-message-template-input {
      margin-top: 20px;
      background: #F4F5F5;

      &.template-text-input-container {
        .text {
          .template-text-input {
            min-height: 120px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .forward-response-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }

  .template-dropdown-container.response-forwarding-container {
    margin-top: 40px;
  }

  .welcome-buttons {
    margin: 70px 0;
    align-self: flex-end;

    .add-section-button, .close-button {
      font: 600 16px Krub;
      padding: 10px 0;
      width: 200px;
      border-radius: 50px;
      border: 2px solid #000000;
      margin-right: 30px;

      &:hover {
        background: #EFEFEF;
      }
    }

    .create-template-button {
      font: 600 16px Krub;
      width: 200px;
      padding: 10px 0;
      border-radius: 50px;
      color: #FFFFFF;
      background: #5200FF;
      border: 2px solid #000000;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
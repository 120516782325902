.add-offer-modal {
  .work-rex-modal {
    background: #FFFFFF;
    max-width: 600px;

    .work-rex-modal-content {
      margin: 20px;

      .title {
        font: 500 20px Krub;
        margin-bottom: 15px;
      }

      .description {
        text-align: center;
        margin-bottom: 20px;
        font-style: italic;
      }

      .campaign-text-input-container {
        margin-right: 0;
        width: 100%;
        margin-bottom: 30px;
      }

      .confirm-button {
        margin-top: 10px;
        border-radius: 50px;
        width: 180px;
        border: 2px solid #000000;
        background: #000000;
        color: #FFFFFF;
        padding: 7px 0;
        font: 500 16px Krub;

        &.disabled {
          opacity: 0.6;
          cursor: auto;

          &:hover {
            opacity: 0.6;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
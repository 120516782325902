.generic-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  font-size: 10px;
  background-color: #EDEDED;

  padding: 2px 6px;
  box-sizing: border-box;
  width: fit-content;
  white-space: nowrap;
  
  &.customer-contact {
    background-color: #5200FF;
    color: #FFFFFF;
  }

  &.employee-contact {
    background-color: #FCED65;
    color: #000000;
  }

  &.task-assigned {
    background-color: #5200FF;
    color: #FFFFFF;
  }

  &.task-tagged {
    background-color: #A988FC;
    color: #FFFFFF;
  }

  &.outstanding-task {
    background-color: #FCED65;
  }

  .generic-label-text {
    font-weight: 600;
  }

  &.broadcast-count {
    background-color: #9F9F9F;
    color: #FFFFFF;
  }

  &.broadcast-frequency {
    background-color: #FCFBB2;
    color: #000000;
  }

  &.broadcast-recipients {
    font-size: 12px;
  }

  &.confirm-broadcast-recipients {
    font-size: 14px;
  }

  &.missing-mobile {
    color: #E44A18;
  }
}
.view-automation-header {
  display: flex;
  flex-flow: row;
  align-items: center;

  .header-container {
    width: 100%;
    display: flex;
    flex-flow: column;

    .automation-title {
      padding: 5px 10px;
      font: 600 22px Krub;
    }
  }

  .description-container {
    display: flex;
    margin-top: 5px;
    width: 100%;

    .description {
      width: 100%;
      font: 400 16px Krub;
      padding: 5px 10px;
      background: transparent;
      border: 1px solid #000000;
      resize: none;

      &.disabled {
        border-color: transparent;
      }
    }

    .editing-button {
      background: transparent;
      margin-left: 5px;

      &:hover {
        transform: scale(1.2);
        opacity: 0.8;
      }
    }
  }

  .setting-buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;

    .automation-active-toggle {
      margin-right: 20px;
    }

    .ellipsis-dropdown-container.automation-dropdown {
      top: -7px;

      .ellipsis-button .automation-dropdown-icon {
        width: 30px;
        margin-left: 15px;
        margin-right: 20px;
        position: relative;
      }

      .ellipsis-dropdown {
        .dropdown-list {
          left: -130px;
        }
      }
    }
  }
}
.engagements-sidebar {
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 25px;
  padding: 15px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;

  height: calc(500px + 20vh);

  @media (max-height: 980px) {
    height: calc(500px + 16vh);
  }

  @media (max-height: 900px) {
    height: calc(450px + 16vh); 
  }

  @media (max-height: 800px) {
    height: calc(420px + 12vh); 
  }

  @media (max-height: 700px) {
    height: calc(400px + 10vh); 
  }

  @media (max-height: 680px) {
    height: 400px;
  }

  .divider {
    border-bottom: solid 2px #EFEFF1;
    min-width: 200px;
    height: 0;
    margin: 20px 0;
  }

  .filter-button-list {
    height: 100%;
    overflow: auto;
    width: 100%;
    margin: 0;

    .filter-button-item {
      .filter-button {
        width: 100%;
        margin: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          .button-text {
            transform: scale(1.0);
            text-decoration: underline;
          }
        }

        .icon {
          width: 14px;
        }

        .button-text {
          margin-left: 15px;
          align-self: flex-start;
        }
      }
    }
  }
}
.work-rex-modal-container.create-template-confirmation {
  .work-rex-modal {
    .work-rex-modal-content {
      .description {
        i {
          color: #5200ff;
        }
      }
    }
  }
}
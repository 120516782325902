.work-rex-modal-container.batch-selection-modal {
  .work-rex-modal {
    background: #FFFFFF;

    .close-modal-button {
      top: -16px;
      right: 20px;
      width: 0;
      height: 0;

      &:hover {
        transform: scale(1);
      }
    }

    .work-rex-modal-content {
      display: flex;
      margin: 0px 20px 0 20px;
      padding: 10px 20px 20px 20px;
      flex-flow: column;
      align-items: center;

      .selection-option {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 25px;

        .selection-title {
          font: 500 18px Krub;
          margin-bottom: 5px;
        }

        .selection-subtitle {
          font: 300 15px Krub;
          margin-bottom: 10px;
        }
      }

      .confirm-buttons {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        .back-button {
          font: 500 14px Krub;
          background: #FFFFFF;
          border: 1.5px solid #000000;
          color: #000000;
          padding: 5px 0px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          width: 160px;
          justify-content: center;
          outline: none;
          height: 30px;

          &:hover {
            background: #EFEFEF;
          }
        }

        .confirm-button {
          font: 500 14px Krub;
          background: #5200FF;
          border: 1.5px solid #000000;
          color: #000000;
          padding: 5px 0px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          width: 160px;
          justify-content: center;
          outline: none;
          height: 30px;
          color: #FFFFFF;

          &.disabled {
            opacity: 0.6;

            &:hover {
              cursor: auto;
              opacity: 0.6;
            }
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

    }
  }
}
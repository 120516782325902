.search-bar-container {
  display: flex;
  flex-flow: column;

  .search-input-container {
    background: #FFFFFF;
    border: 2px solid #000000;
    min-width: 300px;

    .search-input {
      width: 100%;
    }
  }

  .search-list-container {
    width: 100%;
    position: relative;
    display: flex;
    z-index: 5;

    .search-list {
      position: absolute;
      top: 10px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
      border-radius: 10px;
      max-height: 200px;
      overflow: auto;
      
      .search-result {
        width: 100%;
        background: transparent;

        .result-button {
          width: 100%;
          text-align: left;
          padding: 8px 20px;
          background: transparent;
          font: 400 16px Krub;

          &:hover {
            font-weight: 600;
          }
        }
      }
    }

    .no-results {
      position: absolute;
      top: 5px;
      width: 100%;
      text-align: center;
      background: #FFFFFF;
      padding: 25px 0;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
      border-radius: 10px;
    }
  }
}
.templates-display {
  .analytics-table-container {
    height: calc(100vh - 186px);

    @media (max-width: 820px) {
      height: calc(100vh - 186px);
    }
    
    .analytics-table tbody {
      .table-row {
        
        .table-row-header-cell {
          min-width: 250px;

          .title-button {
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            background-color: transparent;
          }
        }

        .table-cell {
          min-width: 100px;

          .download-button, .view-button, .view-message-button, .details-button {
            background: #5200FF;
            color: #FFFFFF;
            width: 100px;
            padding: 5px 0;
            border-radius: 20px;
            font: 700 14px Krub;

            &.no-template {
              background-color: #f0efef;
              cursor: default;
            }

            &.no-responses {
              background-color: #f0efef;
              cursor: default;
            }

            &.no-details {
              background-color: #f0efef;
              cursor: default;
            }

            &.no-message {
              background-color: #f0efef;
              cursor: default;
            }
          }
        }
      }
    }
  }

  .template-analytics-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;

    .view-more-broadcasts-button {
      align-self: flex-start;
      font: 400 15px Krub;
      border: 2px solid #000000;
      border-radius: 50px;
      padding: 2px 10px;
    }
  
    .loading-container {
      height: 30px;
      width: 165px;
    }
  }
}
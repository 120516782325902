.filter-button {
  min-width: 200px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 5px;

  .button-content {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &.selected {
    .button-text {
      font-weight: 600;
    }
  }

  .icon {
    width: 30px;
  }

  .button-text {
    margin-left: 30px;
    font: 400 16px Krub;
  }

  &:hover {
    .button-text {
      transform: scale(1.1);
    }
  }
}
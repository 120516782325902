.recipients-search {
  background: #F5F5F5;
  border: 2px solid #F5F5F5;
  padding: 25px;
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 25px;
  height: 380px;

  .recipients-search-title {
    font: 500 20px Krub;
    margin-bottom: 20px;
  }

  .recipients-search-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;

    .filter-list {
      height: 240px;
    }
  }

  .recipients-search-input-container {
    border-radius: 50px;
    border: 2px solid #000000;
    padding: 4px 15px;
    background: #FFFFFF;
    display: flex;
    align-items: center;

    .recipients-search-input {
      padding: 8px 0;
      font: 500 16px Krub;
      background: transparent;
      border: none;
      outline: none;
      width: 300px;
    }
  }

  .filter-dashboard {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-self: flex-start;
    width: 600px;
  }

  .import-recipients-button {
    border: 2px solid #000000;
    border-radius: 25px;
    background: #FFFFFF;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 500 16px Krub;
    padding: 10px 0;
    outline: none;

    &:hover {
      background: #F5F5F5;
    }
  }
}
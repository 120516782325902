.account-menu-container {
  margin-right: 10px;

  .menu-button {
    border-radius: 20px;
    height: 27px;
    font: 700 14px Krub;
    padding: 0 25px;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
    background: #FFFFFF;

    &:hover {
      background: #d3d3d3;
      cursor: pointer;
    }

    &.dark {
      min-width: 90px;
      margin-right: 0;
      background: #000000;
      color: #FFFFFF;

      &:hover {
        background: #212121;
        cursor: pointer;
      }
    }
  }

  .account-menu {
    position: relative;
    height: 0;
    top: 7px;

    .menu-list {
      position: absolute;
      background: #FFFFFF;
      width: calc(100% + 20px);
      left: -10px;
      display: flex;
      flex-flow: column;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
      border-radius: 10px;
      padding: 10px 0;

      .menu-item {
        width: 100%;

        .menu-button-item {
          font: 400 14px Krub;
          width: 100%;
          padding: 8px 0;

          &.selected {
            font-weight: 600;
          }

          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }
}
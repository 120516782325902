.mobile-navbar-options-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4rem 3rem;
  box-sizing: border-box;

  height: 100%;
  width: 100%;

  .navbar-option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .navbar-option {
      font-weight: 700;
      font-size: 16px;
      margin: 1rem;
      background-color: transparent;
      color: #FFFFFF;
      text-align: left;
  
      &.selected-nav {
        color: #FCED65;
      }
  
      &.active {
        color: #FCEA68;
      }
  
      &:hover {
        color: #FCED65;
        cursor: pointer;
      }
    }

    .join-waitlist-button {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 1px solid rgb(252, 237, 101);
      margin: 0px 0px 0px 8px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      min-width: 64px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px;
      border-radius: 0.5rem;
      font-family: Krub;
      text-transform: none;
      line-height: normal;
      padding: 0.5rem 1rem;
      background-color: rgb(252, 237, 101);
      color: rgb(0, 0, 0);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0rem;
      white-space: nowrap;
      width: 200px;
    }
  
    .sign-in-button {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 1px solid rgb(255, 255, 255);
      margin: 15px 0px 0px 8px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      min-width: 64px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px;
      border-radius: 0.5rem;
      font-family: Krub;
      text-transform: none;
      line-height: normal;
      padding: 0.5rem 1rem;
      background-color: rgb(82, 0, 255);
      color: rgb(255, 255, 255);
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 0rem;
      white-space: nowrap;
      width: 200px;
    }
  
    .navbar-store-review-options {
      display: none;
      // display: flex;
      flex-direction: column;
      margin-left: 1rem;
  
      .sub-nav-option {
        font-weight: 500;
        margin: 0.5rem 1rem;
      }
  
      &.open {
        display: flex;
      }
    }
  }
}
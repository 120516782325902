.channel-selection-button {
  display: flex;
  align-items: center;
  font: 500 16px Krub;
  width: 160px;
  padding: 10px 20px;
  border-radius: 10px;

  .icon {
    width: 40px;
    height: 40px;
  }

  .button-text {
    margin-left: 10px;
  }

  &.selected {
    background: #EAE8FA;
  }

  &:hover {
    background: #EAE8FA;

    .hover-tooltip-container {
      visibility: visible;
    }
  }

  &.disabled {
    &:hover {
      cursor: auto;
      background: #FFFFFF;
    }
  }

  &.view-only {
    &:hover {
      cursor: auto;
    }
  }

  .hover-tooltip-container {
    width: 0;
    height: 0;
    position: relative;
    visibility: hidden;

    .hover-tooltip {
      position: absolute;
      width: max-content;
      padding: 10px 15px;
      background: #EAE0FF;
      font: 500 13px Krub;
      top: -70px;
      left: -30px;
      border-radius: 4px;
    }
  }
}
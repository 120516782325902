.editable-text-input-container {
  width: 100%;
  display: flex;

  .editable-text-input, .editable-text-button {
    font: 600 18px Krub;
    display: flex;
    align-items: flex-start;
    padding: 7px;
    justify-content: center;

    &.disabled {
      &:hover {
        cursor: auto;
      }
    }
  }

  .editable-text-input {
    background: #F0EFEF;
    border: none;
    font-weight: 400;
    width: 100%;
    margin-right: 20px;
    resize: none;
    height: min-content;
    max-height: 40px;
  }
}
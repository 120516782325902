.navbar-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  width: 100%;

  @media (max-width: 600px) {
    margin-right: 20px;
  }

  .nav-option-container {
    position: relative;
    margin: 0 30px;

    @media (max-width: 900px) {
      margin: 10px;
    }
    
    .nav-option {
      background-color: transparent;
      cursor: pointer;
      display: flex;
      font: 500 16px Inter, Helvetica, Arial, sans-serif;
      justify-content: center;
      margin: auto 0;
      padding: 5px 20px;
      position: relative;
      color: #FFFFFF;
      font-weight: 700;
  
      @media (max-width: 1080px) {
        font-size: 14px;
        padding: 0 10px;
      }
  
      @media (max-width: 600px) {
        font-size: 13px;
      }
  
      &.selected-nav {
        color: #FCED65;
      }
  
      &.active {
        color: #FCEA68;
      }
  
      &:hover {
        color: #FCED65;
        cursor: pointer;
      }
    }
  }
}

.web-nav-buttons {
  display: flex;
  flex-flow: row;

  .web-nav-button {
    border-radius: 20px;
    height: 27px;
    font: 700 14px Krub;
    margin-right: 10px;
    padding: 0 25px;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
    background: #FFFFFF;

    &:hover {
      background: #d3d3d3;
      cursor: pointer;
    }

    &.dark {
      min-width: 90px;
      margin-right: 0;
      background: #000000;
      color: #FFFFFF;

      &:hover {
        background: #212121;
        cursor: pointer;
      }
    }
  }
}
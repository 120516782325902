.breadcrumb {
  display: flex;
  padding: 0 0 20px 0;
  align-items: center;

  .breadcrumb-option {
    display: flex;
    padding: 0 10px;
    align-items: center;

    .breadcrumb-nav-button {
      margin-right: 20px;
      font: 300 15px Krub;

      &.has-href {
        font: 300 15px Krub;
        text-underline-offset: 4px;
        position: relative;
        top: -1px;

        &.selected {
          text-decoration: underline;
        }
      }
    }

    .caret-icon-container {
      position: relative;

      .caret-icon {
        width: 12px;
        height: 12px;
      }
    }

  }
}
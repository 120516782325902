.contact-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  background-color: transparent;
  border-bottom: 1px solid rgba(35, 31, 32, 0.15);
  padding: 1rem;
  box-sizing: border-box;

  &.selected {
    background-color: #EAE8FA;
  }

  .contact-card-details {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .contact-name-container {
      display: flex;
      justify-content: flex-start;
      column-gap: 10px;
  
      .contact-name {
        font-size: 14px;
        text-align: left;
      }
  
      .unread-task {
  
      }
    }
  }
}
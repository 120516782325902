.automation-request-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000000;
  box-shadow: 10px 10px;
  padding: 10px;

  .request-input {
    width: 100%;
    resize: none;
    margin-right: 15px;
    height: 80px;
    font: 400 16px Krub;
    border: none;
    padding: 10px;
  }

  .submit-request-container {
    display: flex;
    flex-flow: column;
    margin-right: 20px;

    .submit-request-button {
      .submit-icon {
        width: 45px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .button-label {
      font: 400 14px Krub;
      font-style: italic;
      margin-top: 5px;
    }
  }
}
.automation-request-container {
  border-radius: 30px;
  background-color: #FFFFFF;
  box-shadow: 5px 4px 150px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-flow: column;
  padding-bottom: 40px;
  
  .request-title {
    margin: 30px 40px 0 50px;
    font: 500 18px Krub;
    letter-spacing: 0.5px;
  }

  .automation-request-input-container {
    margin: 20px 50px 0 40px;
  }

  .automation-request-examples-container {
    margin: 50px 100px 0 100px;
  }
}
.work-rex-modal-container.add-recipients-modal {
  .work-rex-modal {
    background: #FFFFFF;
    max-width: 880px;
    min-height: 650px;

    .close-modal-button {
      top: -16px;
      right: 20px;
      width: 0;
      height: 0;

      &:hover {
        transform: scale(1);
      }
    }

    .work-rex-modal-content {
      display: flex;
      margin: 0px 20px 0 20px;
      padding: 10px 20px 20px 20px;

      .buttons {
        align-self: flex-end;

        .cancel-button {
          border-radius: 50px;
          padding: 8px 0px;
          border: solid 2px #000000;
          background: #FFFFFF;
          color: #000000;
          font: 500 16px Krub;
          width: 160px;
  
          &:hover {
            background: #F5F5F5;
          }
        }

        .confirm-button {
          align-self: flex-end;
          font: 500 16px Krub;
          background: #5200FF;
          border: 2px solid #000000;
          color: #FFFFFF;
          padding: 8px 0px;
          border-radius: 50px;
          margin-left: 25px;
          width: 160px;
  
          &:hover {
            opacity: 0.8;
          }
        }
      }

      .recipients-table-container {
        margin-top: 20px;
      }

      .filter-dashboard {
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        .filter-list {
          padding-right: 10px;
        }
      }

      .broadcasts-dropdown-container {
        align-self: flex-start;
        z-index: 4;

        .dropdown-content .dropdown-answer {
          width: 660px;
          height: fit-content;
        }
      }

      .modal-description {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 20px;
        min-width: 800px;

        .title {
          font: 600 20px Krub;
          margin-bottom: 5px;
        }

        .recipients-search-input-container {
          border-radius: 50px;
          border: 2px solid #000000;
          padding: 4px 15px;
          background: #FFFFFF;
          display: flex;
          align-items: center;
          margin-top: 20px;
      
          .recipients-search-input {
            padding: 8px 0;
            font: 500 16px Krub;
            background: transparent;
            border: none;
            outline: none;
            width: 360px;
          }
        }
      }
    }
  }
}
.integration-modal-container.confirm-removal {
  .integration-modal {
    .integration-modal-content {
      display: flex;
      align-items: center;
      flex-flow: column;

      .modal-title {
        font: 700 22px Krub;
        text-align: center;
        margin-bottom: 20px;
  
        @media (max-width: 420px) {
          font-size: 20px;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 300px;
        margin-top: 10px;

        .confirm-button {
          width: 120px;
          padding: 10px 0;
          background: #000000;
          border: 2px solid #000000;
          color: #FFFFFF;
          border-radius: 50px;
          font: 500 16px Krub;

          @media (max-width: 420px) {
            padding: 12px 20px;
            font-size: 14px;
          }
    
          &:hover {
            cursor: pointer;
            background: #2a2a2a;
          }
    
          &.disabled {
            opacity: 0.6;
            cursor: auto;
    
            &:hover {
              background: #000000;
            }
          }
        }

        .cancel-button {
          width: 120px;
          padding: 10px 0;
          background: #FFFFFF;
          color: #1A1A1A;
          border: 2px solid #000000;
          border-radius: 50px;
          font: 500 16px Krub;

          &:hover {
            cursor: pointer;
            background: #CCCCCC;
          }
    
          &.disabled {
            opacity: 0.6;
            cursor: auto;
    
            &:hover {
              background: #FCFCFC;
            }
          }
        }
      }
    }
  }
}
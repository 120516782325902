.plus-button {
  font: 400 16px Krub;
  background: #FFFFFF;
  border: 1.5px solid #000000;
  color: #000000;
  padding: 8px 0px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  width: 160px;
  justify-content: center;
  outline: none;

  .button-text {
    margin-right: 10px;
  }

  &:hover {
    background: #ECECEC;
  }
}
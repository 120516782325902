.template-dropdown-container {
  position: relative;

  .template-dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 250px;
    font: 500 16px Krub;
    padding: 6px 15px;
    background: #F0EFEF;
    border: 2px solid #000000;
    border-radius: 5px;

    .text {
      margin-right: 20px;
    }

    &.disabled {
      opacity: 0.6;

      &:hover {
        cursor: auto;
      }
    }
    
    .downwards-caret-icon {
      width: 15px;
    }
  }

  &.open {
    .template-dropdown-button {
      border-bottom: 2px solid transparent;
      border-radius: 5px 5px 0 0;
      
      .downwards-caret-icon {
        transform: rotate(180deg);
      }
    }
  }

  .template-dropdown-button-container {
    display: flex;
    align-items: center;

    &.displaying-selected {
      .template-dropdown-button {
        border-radius: 5px 0px 0px 5px;
      }
    }

    .template-dropdown-button-selection {
      font: 500 16px Krub;
      padding: 6px 15px;
      border: 2px solid #000000;
      border-radius: 0px 5px 5px 0; 
      border-left: none;
      position: relative;
      left: -2px;
    }
  }

  .template-dropdown-list-container {
    width: 0;
    height: 0;
    position: absolute;

    .template-dropdown-list {
      position: relative;
      top: -2px;
      width: 246px;
      border: 2px solid #000000;
      max-height: 200px;
      overflow: auto;
      z-index: 5;

      .template-dropdown-item {
        width: 100%;

        &.selected {
          .template-dropdown-item-button {
            font-weight: 600;
          }
        }

        .template-dropdown-item-button {
          width: 100%;
          padding: 15px 8px;
          font: 400 16px Krub;
          color: #231F20;

          &:hover {
            background: #EFEFEF;
          }
        }
      }
    }
  }
}
.email {
  margin-top: 10px;

  .email-input-container {
    display: flex;
    flex-flow: column;

    .message-label, .title-label {
      font: 400 18px Krub;
      margin-bottom: 15px;
    }

    .message-label {
      margin-top: 20px;
    }
    
    .title-input {
      background: #FFFFFF;
      padding: 10px 10px;
      border: 2px solid #000000;
      font: 400 16px Krub;
    }

    .template-text-input-container {
      background: #FFFFFF;
      padding: 5px;

      .text {
        .template-text-input {
          padding: 0 5px;
        }
      }

      &.message-input {
        .text {
          .template-text-input {
            min-height: 200px;
          }
        }
      }
    }
  }
}
.add-group-modal-body {
  display: flex;
  flex-direction: column;

  .add-group-input {
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    
    &.show-placeholder {
      font-style: italic;
    }
  }

  .error-text {
    color: red;
    font-size: 12px;
  }
  
  .group-tags-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 5px 1rem;
  }
}
.template-card {
  display: flex;
  flex-flow: column;
  border: 2px solid #000000;
  width: 300px;
  height: 210px;
  padding: 15px 25px;

  &.draft {
    background: #EFEFF1;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .template-label {
      font: 400 14px Krub;
    }
  }

  .template-title {
    font: 500 19px Krub;
    margin-bottom: 8px;
  }

  .template-details {
    height: 160px;
  }

  .template-description {
    font: 400 13px Krub;
    font-style: italic;
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.single-button {
      align-self: flex-end;
    }

    .recommendation-url {
      color: #5200FF;
      text-decoration: underline;
      font: 400 16px Krub;
      font-style: italic;
    }

    .draft-label {
      background: #FFFFFF;
      padding: 6px 25px;
      font: 500 16px Krub;
      position: relative;
      left: -25px;

      &::after {
        content: '';
        position: absolute;
        left: 98px;
        top: 0px;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        
        border-left: 16px solid #FFFFFF;
        clear: both;
      }
    }
  }

  .view-more-button {
    align-self: flex-end;
    width: 130px;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 5px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font: 600 13px Krub;
    background: #5200FF;
    color: #FFFFFF;
  }
}
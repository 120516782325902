.assistant-selection {
  padding: 30px 40px 30px 30px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 30px;
  width: calc(100% - 80px);
  height: calc(100% - 60px);
  display: flex;
  flex-flow: column;

  min-height: calc(500px + 20vh);

  @media (max-height: 980px) {
    min-height: calc(500px + 16vh);
  }

  @media (max-height: 900px) {
    min-height: calc(450px + 16vh); 
  }

  @media (max-height: 800px) {
    min-height: calc(420px + 12vh); 
  }

  @media (max-height: 700px) {
    min-height: calc(400px + 10vh); 
  }

  @media (max-height: 680px) {
    min-height: 400px;
  }

  .selection-title {
    font: 500 20px Krub;
    margin-left: 10px;
  }

  .selection-content {
    max-height: 440px;
    min-height: 440px;
    overflow: auto;
    margin-left: 10px;
  }

  .buttons {
    align-self: flex-end;
    margin-top: 20px;

    .cancel-button {
      font: 500 16px Krub;
      background: #FFFFFF;
      border: 2px solid #000000;
      color: #000000;
      padding: 4px 45px;
      border-radius: 50px;
      margin-left: 20px;

      &:hover {
        background: #ECECEC;
      }
    }

    .activate-button {
      font: 500 16px Krub;
      background: #5200FF;
      border: 2px solid #000000;
      color: #FFFFFF;
      padding: 4px 45px;
      border-radius: 50px;
      margin-left: 20px;
      max-width: 155px;

      &:hover {
        opacity: 0.8;
      }

      &.loading {
        &:hover {
          opacity: 1.0;
        }
      }
    }
  }
}
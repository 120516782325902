.direct-message {

  .message-container {
    display: flex;
    flex-flow: column;

    .message-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
    }

    .message-label {
      font: 400 18px Krub;
    }

    .template-text-input-container {
      background: #FFFFFF;
      padding: 5px;

      .text {
        width: 100%;
        overflow: scroll;
        margin-right: 0;
        overflow-x: hidden;

        .template-text-input {
          padding: 5px;
          min-height: 220px;
          max-height: 220px;
          width: fit-content;
        }
      }

      .word-count-and-editing-button {
        margin-right: 2px;
      }
    }
  }
}
.filter-selection-dropdown-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;

  background-color: #FFFFFF;
  max-height: 235px;
  position: relative;
  width: 185px;
  flex: 2;

  .filter-selection-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    color: #000000;

    margin-bottom: 1px;
    box-sizing: border-box;
    
    background-color: #FFFFFF;
    border-radius: 100px;
    border: 1px solid #231F20;
    
    transition: all 0.25s ease;
    z-index: 1;

    padding: 7px 15px;
    position: relative;

    white-space: nowrap;
    column-gap: 5px;

    cursor: pointer;

    @media (max-width: 360px) {
      font-size: 12px;
      padding: 15px;
    }

    .dropdown-display-text {
      font-size: 12px;
    }
  }

  .filter-selection-header-icon {
    height: 10px;
    transition: all 0.2s;
    transform: rotate(90deg);
  }

  .open {
    transform: rotate(-90deg);
    transition: all 0.2s;
  }

  .filter-selection-dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow-y: scroll;
    font-size: 12px;
    opacity: 1;
    position: absolute;
    z-index: 2;
    background: #FFF;
    box-shadow: 1px 5px 10px 0 #0003;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    .filter-selection-dropdown-answer {
      padding: 10px;
      box-sizing: border-box;
      width: 500px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .filter-selection-dropdown-content > span {
    display: inline-block;
  }
}
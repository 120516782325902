.interactions-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  padding: 1rem;
  box-sizing: border-box;

  .interaction-list-status {
    .interaction-status-subtitle {
      font-size: 12px;
    }
  }
}
.interactions-inbox {
  .flatfile_iframe-wrapper {
    .flatfile_iFrameContainer {
      height: 80% !important;
      max-height: 750px;
    }
  }

  .interaction-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;

    .new-interaction-dropdown {
      position: relative;

      .dropdown-options {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        position: absolute;
        margin-top: 5px;

        .dropdown-option {
          width: 100%;

          .dropdown-option-button {
            width: 100%;
            font-size: 13px;
            padding: 8px 15px;

            &:hover {
              background: #E5E5E5;
            }
          }
        }
      }
    }

    .new-interaction-button {
      background-color: #FFFFFF;
      border-radius: 100px;
      border: 1px solid #000000;
      box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 4px 30px;
      box-sizing: border-box;
      font-size: 13px;
    }
  }

  .interaction-list {
    box-sizing: border-box;

    .interaction-list-status {
      font-size: 14px;
      
      .interaction-status-subtitle {
        color: #5200FF;
      }
    }
  }
}
.create-modal {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .create-modal-header {
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .create-modal-body {
    display: flex;
    flex-direction: column;

    .confirm-error-text {
      color: red;
      font-size: 12px;
      min-height: 20px;
      text-align: center;
    }

    .modal-details {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-bottom: 1rem;
    }
  }

  .create-modal-footer {
    display: flex;
    justify-content: center;
    column-gap: 1rem;

    .footer-button {
      border-radius: 30px;
      border: 2px solid #000000;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      padding: 5px 0px;
      box-sizing: border-box;
      flex: 1;

      &.create-record {
        background-color: #5200FF;
        color: #FFFFFF;
      }
    }
  }
}
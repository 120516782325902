.filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;

  .delete-filter-button {
    background: transparent;
  }

  .filter-sections {
    display: flex;
    column-gap: 1rem;
    min-width: 0;

    .react-datepicker-wrapper {
      width: 100%;
      flex: 2;
      
      .react-datepicker__input-container {
        width: 100%;
  
        & input {
          width: 100%;
          padding: 5px 10px;
          box-sizing: border-box;
          border: 2px solid #9F9F9F;
          cursor: pointer;
        }
      }
    }
  
    .react-datepicker-popper {
      z-index: 2;
  
      .react-datepicker {
        box-shadow: 0px 0px 30.5201px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 15px;
        border: none;
    
    
        .react-datepicker__navigation--previous {
          right: 40px;
          top: 25px;
          left: auto;
        }
        
        .react-datepicker__navigation--next {
          right: 7px;
          top: 25px;
        }
    
        .react-datepicker__navigation-icon:before {
          border-color: #121212;
        }
    
        .react-datepicker__month-container {
    
          .react-datepicker__header {
            background-color: #FFFFFF;
            border-bottom: none;
    
            .react-datepicker__current-month {
              color: #121212;
              display: flex;
              padding: 10px;
              font: 900 1.1rem Krub;
            }
          }
    
          .react-datepicker__day--outside-month {
            visibility: hidden;
          }
    
          .react-datepicker__day-name,  .react-datepicker__day, .react-datepicker__time-name {
            font-family: Krub;
          }
    
          .react-datepicker__day--selected {
            background: #5200FF;
            color: #FFFFFF;
          }
    
          .react-datepicker__day--keyboard-selected {
            background-color: transparent;
            color: #000000;
          }
    
          .react-datepicker__day--highlighted.react-datepicker__day--keyboard-selected,  .react-datepicker__day--highlighted {
            background: #5200FF;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
.campaign-input-section {
  .section-header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    
    .section-title {
      font: 500 16px Krub;
      margin-right: 15px;
    }
  }

  .section-description {
    font: 400 15px Krub;
    margin-bottom: 15px;
  }
}
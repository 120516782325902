.view-offers-modal {
  .work-rex-modal {
    background: #FFFFFF;
    max-width: 600px;

    .work-rex-modal-content {
      margin: 20px;

      .title {
        font: 500 20px Krub;
        margin-bottom: 15px;
      }

      .description {
        text-align: center;
        margin-bottom: 20px;
        font-style: italic;
      }

      .offers {
        margin-bottom: 15px;
        display: flex;
        flex-flow: column;
        align-items: center;
        max-height: 180px;
        overflow: auto;

        .offer {
          max-width: 460px;
          width: fit-content;
          display: flex;
          align-items: center;
          background: #FEFFD8;
          padding: 5px 12px;
          margin-bottom: 10px;

          .offer-text {
            font: 400 16px Krub;
            margin-right: 10px;
            color: #231F20;
          }

          .remove-offer-button {
            font: 400 20px Krub;
            background: #FEFFD8;
            color: #231F20;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

      .confirm-button {
        margin-top: 10px;
        border-radius: 50px;
        width: 180px;
        border: 2px solid #000000;
        background: #000000;
        color: #FFFFFF;
        padding: 7px 0;
        font: 500 16px Krub;

        &.disabled {
          opacity: 0.6;
          cursor: auto;
          
          &:hover {
            opacity: 0.6;
          }
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
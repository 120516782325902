.communications-nav-button {
  border: 2px solid #000000;
  border-radius: 10px 10px 0 0;
  min-width: 131px;
  box-sizing: border-box;
  padding: 8px 0;
  font: 300 16px Krub;

  &.selected {
    background: #FEFFD8;
    font: 500 16px Krub;
  }
}
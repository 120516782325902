.template-text-input-container {
  padding: 5px 5px 5px 5px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  flex-flow: column;

  &.new-broadcast-message {
    border: 2px solid rgba(0,0,0,.25);
  }

  .text {
    width: 100%;

    .campaign-name {
      display: flex;
      padding: 6px 12px;
      background: #A988FC;
      align-items: center;
      color: #FFFFFF;
      font: 500 14px Krub;
      border-radius: 30px;
      width: fit-content;

      .remove-button {
        margin-left: 15px;
        background: transparent;
        color: #FFFFFF;
        font: 300 16px Krub;
        transform: scale(1.7);
        position: relative;
        top: -1px;
        margin-right: 3px;

        &:hover {
          transform: scale(1.9);
          opacity: 0.8;
        }
      }
    }

    .template-text-input {
      font: 400 16px Krub;
      width: calc(100% - 30px);
      border: none;
      resize: none;
      padding: 10px 15px;
      min-height: 20.5px;
      border: 2px solid transparent;
      line-height: 35px;
      overflow-wrap: anywhere;
      white-space: pre-wrap;
      outline: none;
      max-height: 220px;
      overflow: auto;

      &:hover {
        cursor: auto;
      }

      &.editing{
        &:hover {
          cursor: text;
        }
      }

      &[contenteditable=true]:empty:before {
        content: attr(placeholder);
        display: block;
        color: #6A6A6A;
        font-style: italic;
      }

      .container {
        .variable {
          background: #A988FC;
          color: #FFFFFF;
          padding: 3px 8px;
          border-radius: 50px;
          font-size: 14px;
          text-wrap: nowrap;
        }

        .numeric {
          background: #88A9FC;
          color: #FFFFFF;
          padding: 3px 8px;
          border-radius: 50px;
          font-size: 14px;
          text-wrap: nowrap;
        }
      }
    }
  }

  .word-count-and-editing-button {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 100%;
    align-self: flex-end;
    
    .word-count {
      font: 400 12px Krub;
      background: transparent;
      width: 42px;
    }
  }

  .editing-button {
    margin-right: 20px;
    background: transparent;
    
    &:hover {
      transform: scale(1.2);
    }
  }
}
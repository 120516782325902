.search-groups-container {

  .search-groups {
    position: relative;

    .search-groups-dropdown {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      box-shadow: 1px 5px 10px 0px rgba(0, 0, 0, 0.20);
      padding: 1rem 2rem;
      box-sizing: border-box;
      border-radius: 0px 0px 10px 10px;
      width: 100%;
      row-gap: 5px;
      background-color: #FFFFFF;
      max-height: 250px;
      overflow-y: scroll;
      z-index: 1;

      &::-webkit-scrollbar {
        display: none;
      }

      .no-groups-button {
        pointer-events: none;
      }
    }
  }

  .group-tags-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 5px;
    margin: 1rem;
  }
}
.unable-to-send-error-text {
  color: red;
  font-size: 12px;
  min-height: 16px;
}

.confirm-broadcast-body {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 5px 0 3rem;

  .broadcast-schedule {
    display: flex;
    justify-content: space-between;
  }

  .broadcast-recipients-row {
    display: flex;
    justify-content: space-between;

    .broadcast-recipients {
      display: flex;
      column-gap: 10px;
    }

    .broadcast-recipient-count {
      color: #9F9F9F;
    }
  }

  .broadcast-message-container {
    position: relative;

    .broadcast-message {
      border: 1px solid #9F9F9F;
      padding: 1rem;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;

      .matching-variable {
        color: #5200FF;
      }

      .missing-variable {
        color: #E44A18;
      }
    }

    .review-next-broadcast-button {
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: transparent;

      &.prev-button {
        left: -15px;
      }
  
      &.next-button {
        right: -15px;
      }

      .next-broadcast-icon {
        height: 15px;
      }
    }
  }

  .broadcast-review-error-container {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    column-gap: 10px;

    .review-broadcast-error-icon {

    }

    .broadcast-error-text-container {
      display: flex;
      flex-direction: column;
      max-width: 65%;
      row-gap: 5px;

      .confirm-broadcast-error-text {
        color: red;
        font-size: 12px;
      }
    }
  }
}
.recall-assistant {
  .assistant-selection-section {
    margin-top: 20px;
  }

  .channel-selection-options {
    margin: 15px 0;

    .channel-selection-button {
      width: 140px;
      font: 400 14px Krub;

      .icon {
        width: 33px;
        height: 33px;
      }
    }
  }
}
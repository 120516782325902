.engagement-item {
  display: grid;
  grid-template-columns: 4fr 8fr 4fr 2fr 2fr 0.5fr;
  column-gap: 1rem;

  border: 2px solid #000000;
  padding: 2rem;
  box-sizing: border-box;

  .activate-button {
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #000000;
    width: 100px;
    padding: 5px 0;
    border-radius: 20px;
    font: 700 14px Krub;
    
    &.active {
      background-color: #67CE67;
      color: #FFFFFF;
      cursor: default;
      border: unset;
    }
    
    &.draft {
      background-color: #D9D9D9;
      color: #636363;
      cursor: default;
      border: unset;
    }

    &.requested {
      background-color: #D9D9D9;
      color: #636363;
      border: unset;
    }
  }

  .edit-engagement-button {
    background-color: transparent;
    position: relative;

    .edit-engagement-icon {

    }
  }
}
.preview-tool-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .buttons {
    display: flex;
    align-items: center;
    position: relative;
    top: 10px;

    .upload-button-container {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .upload-button {
      border: 2px solid #000000;
      border-radius: 25px;
      background: #FFFFFF;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      font: 500 16px Krub;
      padding: 8px 0;
      outline: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 50px;

      &.has-attachment {
        margin-bottom: 10px;
      }

      &.disabled {
        &:hover {
          cursor: auto;
          background: #FFFFFF;
        }
      }
  
      .upload-icon {
        margin-right: 10px;
        width: 17px;
      }
  
      &:hover {
        background: #F5F5F5;
      }
    }

    .attachment-container {
      width: fit-content;
    }

    .submit-button {
      align-self: flex-end;
      font: 500 16px Krub;
      background: #5200FF;
      border: 2px solid #000000;
      color: #FFFFFF;
      padding: 8px 45px;
      border-radius: 50px;
      margin-left: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 50px;
  
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  width: 100%;
  position: fixed;
  z-index: 999;

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    background-color: #5200ff;
    position: relative;
    height: 60px;
    width: 100%;

    padding: 0 1rem;

    @media (max-width: 600px) {
      height: 40px;
    }

    .logo-container {
      height: 80px;
      width: 80px;
      position: absolute;
      background-color: transparent;

      border-radius: 50%;

      @media (max-width: 600px) {
        height: 60px;
        width: 60px;
      }

      .workrex-logo {
        width: 130px;
      }
    }
  }
}
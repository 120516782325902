.template-attachment-button-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .attachment-button {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    border: 2px solid #000000;
    border-radius: 50px;
    width: 240px;
    justify-content: center;
    .button-icon {
      width: 16px;
    }

    .button-text {
      margin-left: 12px;
      font: 500 16px Krub;
    }

    &:hover {
      background: #EFEFEF;
    }

    &:disabled {

      &:hover {
        background: #FFFFFF;
        cursor: auto;
      }
    }
  }


  .attachment-container {
    height: 0;

    .attachment {
      width: fit-content;
      position: relative;
      top: 10px;
    }
  }
}
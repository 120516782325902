.login-input-container {
  position: relative;
  margin: 1rem 0;

  input {
    height: 42px;
    border: 1px solid #c2c8d0;
    border-radius: 3px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;

    transition: all .15s ease-in-out,top .15s ease-in-out,padding .15s ease-in-out;

    &:focus {
      outline: none;
      border-color: #9775E3;
    }

    &:focus + .login-input-label {
      top: 0px;
      transform: scale(0.88) translateX(-16px) translateY(-50%);
      color: #5200FF;
    }

    &.has-input + .login-input-label {
      top: 0px;
      transform: scale(0.88) translateX(-16px) translateY(-50%);
    }

    &.has-error + .login-input-label {
      color: #FF0000;
    }
  }

  .login-input-label {
    position: absolute;
    top: 11px;
    left: 16px;
    font-size: 14px;
    pointer-events: none;
    background-color: #FFFFFF;
    padding: 1px 6px;
    
    transition: transform .15s ease-in-out,top .15s ease-in-out,padding .15s ease-in-out;
  }

  // Additional css for when using react-tel-input input fields for sms rather than standard input field.
  .react-tel-input {
    .form-control {
      height: 42px;
      border: 1px solid #c2c8d0;
      border-radius: 3px;
      width: 100%;
      // padding: 0 16px;
      box-sizing: border-box;
  
      transition: all .15s ease-in-out,top .15s ease-in-out,padding .15s ease-in-out;
  
      &:focus {
        outline: none;
        border-color: #9775E3;
      }
    }

    &:focus-within + .login-input-label {
      top: 0px;
      transform: scale(0.88) translateX(-14px) translateY(-50%);
      color: #5200FF;
    }

    &.has-input + .login-input-label {
      top: 0px;
      transform: scale(0.88) translateX(-14px) translateY(-50%);
    }

    &.has-error + .login-input-label {
      color: #FF0000;
    }

    & + .login-input-label {
      position: absolute;
      top: 11px;
      left: 48px;
      font-size: 14px;
      pointer-events: none;
      background-color: #FFFFFF;
      padding: 1px 6px;
      
      transition: transform .15s ease-in-out,top .15s ease-in-out,padding .15s ease-in-out;
    }
  }
}
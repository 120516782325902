.time-dropdown-container {
  display: flex;
  flex-flow: column;

  .time-selector-container {
    display: flex;
    align-items: center;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DEDDDD;
    width: 44.5px;
    height: 44.5px;

    .clock-icon {
      width: 28px;
      height: 28px;
    }
  }

  .time-dropdown-button {
    font: 500 18px Krub;
    padding: 0 30px;
    height: 44.5px;
    background: #F0EFEF;
    display: flex;
    align-items: center;

    .button-text {
      margin-right: 15px;
      width: 50px;
    }

    .dropdown-arrow {
      width: 8px;
      position: relative;
    }

    &.open {
      .dropdown-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .time-list-container {
    position: relative;
    left: 44.5px;
    z-index: 2;

    .time-list {
      position: absolute;
      max-height: 150px;
      overflow: auto;
      width: 132.7px;
      background: #F0EFEF;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 5px 0;

      .time-option-item {
        width: 100%;

        .time-option-selector {
          width: 100%;
          background: #F0EFEF;
          font: 400 16px Krub;
          padding: 6px 0;

          &:hover {
            background: #E1E1E1;
          }
        }

        &.selected {
          .time-option-selector {
            font-weight: 700;
          }
        }
      }
    }
  }
}
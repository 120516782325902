.customer-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .customer-rows {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .show-more-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
  }

  .list-controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    height: 40px;
    padding: 1rem 10px 0;
    box-sizing: border-box;

    .filter-sort-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
    }

    .customer-count {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;
      box-sizing: border-box;
  
      .customer-label {
        font-size: 12px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.44);
      }
    }
  }
}
.preview-wrapper {
  .preview-title {
    font: 500 22px Krub;
    margin-bottom: 20px;
  }

  .editable-text-input-container {
    .editable-text-button {
      font: 500 20px Krub;
      padding-left: 0;
    }

    .editable-text-input {
      max-height: inherit;
      height: 21px;
      margin-bottom: 10px;
      margin-right: 0;
    }
  }

  .preview-content {
    display: flex;
    gap: 50px;

    .preview-editor {
      flex: 4;
      display: flex;
      flex-flow: column;
    }

    .preview-image {
      max-width: 400px;
    }
  }
}
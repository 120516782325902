.task-type-selections {
  .task-type-list {
    display: flex;
    flex-flow: column;

    .task-type {
      width: 100%;

      .task-type-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 320px;
        margin: 5px 0;

        .task-type-identifier {
          display: flex;
          align-items: center;

          .task-type-name {
            font: 500 16px Krub;
            margin-left: 15px;
          }
        }
      }

      .team-picker-container {
        margin: 15px 0;
      }
    }
  }
}
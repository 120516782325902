.time-option {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px 4px 3px 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  
  font-weight: 600;

  &:hover {
    background-color: #5200FF;
    color: #FFFFFF;
  }

  &.selected {
    background-color: #5200FF;
    color: #FFFFFF;
  }
}
/* styling lable */
.toggle {
  --width: 90px;
  --height: calc(var(--width) / 3);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;

  &.task-hide-completed-toggle {
    height: 25.5px;
    width: 135px;
  }

  // Hide checkbox.
  & input {
    display: none;

    &:checked + .slider {
      background-color: #FFFFFF;

      &::before {
        transform: translateX(calc(var(--width) - var(--height)));
        background-color: #67CE67;
      }

      &.task-hide-completed-toggle {
        &::before {
          transform: translateX(calc(135px - 25.5px));
        }
      }
    }

    &:checked~.labels::after {
      opacity: 0;
    }

    &:checked~.labels::before {
      opacity: 1;
    }
  }

  & .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: #FFFFFF;
    transition: all 0.4s ease-in-out;
    border: 2px solid #231F20;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--height) - 6px);
      height: calc(var(--height) - 6px);
      margin-top: 3px;
      margin-left: 3px;
      border-radius: calc(var(--height) / 2);
      background-color: #F0CB45;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      transition: all 0.4s ease-in-out;
    }

    &.task-hide-completed-toggle {
      border: 1px solid #000000;

      &::before {
        height: 20px;
        width: 20px;
      }
    }
  }

  & .labels {
    position: absolute;
    top: 11px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 11px;
    font-weight: 700;
    transition: all 0.4s ease-in-out;
    color: #000000;

    &::after {
      content: attr(data-off);
      position: absolute;
      right: 5px;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }

    &::before {
      content: attr(data-on);
      position: absolute;
      left: 10px;
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    &.task-hide-completed-toggle {
      top: 6px;
      font-size: 13px;
      font-weight: 400;

      &::after {
        right: 6px;
      }
    }
  }
}
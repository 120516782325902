.task-column {
  display: flex;
  flex-flow: column;
  background: #FFFFFF;
  padding: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  min-width: 440px;

  @media (max-width: 1260px) {
    min-width: 360px;
  }

  @media (max-width: 980px) {
    min-width: 280px;
  }

  @media (max-width: 800px) {
    box-shadow: none;
    padding: 10px 0;
    min-width: auto;
  }

  .column-title {
    margin: 10px 0 20px 0;
    font: 400 18px Krub;
    color: #5200FF;
    padding: 0 35px;

    @media (max-width: 1260px) {
      padding: 0 15px;
    }

    @media (max-width: 800px) {
      margin-bottom: 30px;
      font: 400 22px Krub;
      padding: 0;
    }
  }

  .task-list {
    max-height: 800px;
    overflow: auto;
    padding: 0 35px;

    @media (max-width: 1260px) {
      padding: 0;
    }

    @media (max-width: 800px) {
      max-height: none;
    }

    .task-card {
      margin-bottom: 30px;
    }
  }

}
.integrations-list {
  display: grid;
  margin: 0 0 0 20px;
  grid-template-columns: repeat(auto-fill, 300px);
  width: 100%;
  max-height: calc(500px + 20vh);

  @media (max-height: 980px) {
    max-height: calc(500px + 16vh);
  }

  @media (max-height: 900px) {
    max-height: calc(450px + 16vh); 
  }

  @media (max-height: 800px) {
    max-height: calc(400px + 10vh); 
  }

  @media (max-height: 700px) {
    max-height: calc(380px + 10vh); 
  }

  @media (max-height: 680px) {
    max-height: 400px;
  }

  overflow: auto;
  position: relative;
}
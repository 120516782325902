.reorder-assistant {
  .assistant-selection-section {
    margin-top: 20px;
  }
  
  .offer-list {
    width: 100%;
  }

  .offer-input-container {
    margin-top: 15px;

    .delete-button {
      left: 20px;
      top: -20px;
    }
  }

  .plus-button {
    margin-top: 20px;
  }
}
.file-card {
  display: flex;
  flex-flow: column;
  border: 2px solid #000000;
  width: 280px;
  height: 190px;
  padding: 15px 25px;
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .file-label {
      font: 400 14px Krub;
    }

    .ellipsis-dropdown-container {
      .ellipsis-dropdown {
        .dropdown-list {
          .dropdown-option {
            .dropdown-selection {
              width: 100px;
            }
          }
        }
      }
    }
  }

  .file-title {
    font: 500 18px Krub;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-details {
    height: 160px;
  }

  .file-description {
    font: 400 15px Krub;
    font-style: italic;
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
  }

  .tag-label {
    background: #FEFFD8;
    padding: 10px 5px 10px 15px;
    font: 500 14px Krub;
    position: relative;
    left: -55px;
    width: 100px;

    &::after {
      content: '';
      position: absolute;
      left: 116px;
      top: 0px;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      
      border-left: 20px solid #FEFFD8;
      clear: both;
    }
  }

  .view-more-button {
    align-self: flex-end;
    width: 130px;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 5px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font: 600 13px Krub;
    background: #5200FF;
    color: #FFFFFF;

    &:hover {
      opacity: 0.8;
    }
  }
}
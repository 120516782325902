.contact-detail-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .contact-detail {
    display: flex;
    flex-direction: column;
    min-height: 0;

    // Header
    .contact-detail-header {
      display: flex;
      column-gap: 10px;

      padding: 1rem;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(35, 31, 32, 0.15);

      .header-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .header-icon {
          border: 1px solid #000000;
          border-radius: 50px;
          padding: 1rem;
          position: relative;

          .header-text {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);
          }
        }
      }

      .header-details {
        font-size: 12px;

        .header-contact-name {
          display: flex;
          column-gap: 10px;
          margin-bottom: 5px;
        }
        
        .header-last-interaction-title {
          color: #231F20;

          .header-interaction-last {
            font-style: italic;
          }

          .header-interaction {
            font-weight: 400;
          }
        }
      }
    }

    // Body
    .contact-detail-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2rem;
      padding: 1rem;
      box-sizing: border-box;
      min-height: 0;
      overflow-y: scroll;

      .body-detail {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .detail-heading {
          color: #9F9F9F;
          font-weight: 600;
          font-size: 12px;
        }

        .detail-value {
          font-size: 14px;
        }
      }
    }
  }

  // Footer
  .contact-detail-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    margin-top: 2rem;

    .close-detail-view {
      padding: 5px 15px;
      box-sizing: border-box;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      border-radius: 30px;
      border: 1px solid #000;
      background: #5200FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
    }
  }
}
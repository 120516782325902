.task-list-view-card {
  display: flex;
  padding: 10px 2px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(35,31,32,.15);

  @media (max-width: 820px) {
    padding: 12px 2px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .task-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;

    &.not-selected {
      display: none;
    }

    @media (max-width: 820px) {
      padding: 4px;
    }

    .task-type-icon {
      width: 15px;
      height: 15px;

      @media (max-width: 820px) {
        width: 12px;
        height: 12px;
      }
    }
  }

  &:hover .task-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
  }

  &:hover .task-details > .task-heading {
    margin-right: 0px;
  }

  .task-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100%;
    row-gap: 4px;

    .task-heading {
      display: flex;
      column-gap: 10px;
      font-size: 12px;
      font-weight: 600;
      margin-right: 31px;

      .task-label {
        text-align: left;
        font-weight: 500;
      }
    }

    .task-assigned-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
    }
  }
}
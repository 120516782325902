.navigation-tab {
  border: 2px solid #000000;

  .navigation-tab-button {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    background: transparent;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .navigation-display {
      display: flex;
      background: transparent;
      align-items: center;

      .icon {
        margin-right: 15px;
        width: 25px;
      }
  
      .text {
        font: 500 15px Krub;
        text-wrap: wrap;
        text-align: left;
      }
    }

    .count {
      font: 600 16px Krub;
      color: #9F9F9F;
    }
  }

  &.selected {
    background: #FEFFD8;
  }
}
.template-page-container {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px 0;
  margin: 10px auto 30px auto;
  max-width: 1560px;

  .template-title-header {
    display: flex;
    flex-flow: column;
    width: 100%;

    .template-title-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      .template-title {
        font: 600 18px Krub;
        margin: 10px 0;
        padding: 0 30px;
      }

      .template-buttons-container {
        display: flex;
        flex-flow: column;
        align-items: center;

        .template-buttons {
          margin: 0 30px;
          align-self: flex-end;
      
          .save-draft-button, .close-button, .create-template-button {
            font: 600 16px Krub;
            padding: 6px 0;
            width: 180px;
            border-radius: 50px;
            border: 2px solid #000000;
            margin-right: 15px;
      
            &:hover {
              background: #EFEFEF;
            }
          }

          .save-draft-button {
            background: #FEFFD8;

            &:hover {
              opacity: 0.7;
              background: #FEFFD8;
            }
          }

          .close-button {
            margin-right: 0;
          }
      
          .create-template-button {
            margin-right: 0;
            color: #FFFFFF;
            background: #5200FF;
            border: 2px solid #000000;
      
            &:hover {
              opacity: 0.8;
              background: #5200FF;
            }
          }
        }
      }
    }

    .error-text {
      text-align: center;
      max-width: 720px;
      font-size: 16px;
      align-self: center;
      margin: 15px 0;
    }
  }

  .template-details {
    max-height: 900px;

    @media (max-height: 980px) {
      max-height: 700px;
    }

    @media (max-height: 900px) {
      max-height: 460px;
    }

    @media (max-height: 780px) {
      max-height: 450px;
    }

    @media (max-height: 700px) {
      max-height: 420px;
    }
    overflow: auto;
    padding: 0 30px 20px 30px;
  }
}
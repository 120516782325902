.channel-selection-options {
  margin: 40px 0 20px 0;

  .selection-title {
    font: 500 18px Krub;
  }

  .channel-selection-list {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    gap: 15px;
  }
}
.campaign-source-display {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px 40px;
  margin: 10px auto 30px auto;
  max-width: 1560px;
  border-radius: 30px;
  margin-top: 10px;

  .source-title {
    font: 500 18px Krub;
    margin-right: 15px;
  }

  .sources {
    display: flex;
    margin-top: 25px;
    margin-bottom: 20px;
    gap: 30px;

    @media (max-width: 1400px) {
      gap: 10px;
    }

    @media (max-width: 980px) {
      gap: 5px;
    }

    .campaign-source {
      flex: 1;
    }
  }
}
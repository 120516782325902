.recipients-table-container {
  border: 1px solid #CCCCCC;
  max-height: 420px;
  overflow: auto;
  position: relative;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-flow: column;

  .recipients-table {
    .table-header {
      text-align: center;
      position: sticky;
      top: 0;
      box-shadow: inset 0 -2px 0 #000000;
      background: #FFFFFF;
      z-index: 2;

      .table-row {
        display: flex;
        text-align: center;
        position: sticky;
        top: 0;
        box-shadow: inset 0 -1px 0 #CCCCCC;
        background: #FFFFFF;
        z-index: 2;
      }
    }

    th, td {
      padding: 5px 0;
      text-align: left;

      &.selected-cell {
        width: 50px;
        position: relative;
        left: 10px;
      }

      &.full-name-cell {
        width: 200px;
      }

      &.email-cell {
        width: 250px;
      }

      &.mobile-cell {
        width: 145px;
      }

      &.birthday-cell {
        width: 150px;
      }
    }

    .table-body {
      border: 1px solid #CCCCCC;

      tr {
        display: flex;
      }

      td {
        padding: 10px 0;
      }
    }

  }
}
.toggle-button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border: 1px solid #231F20;
  color: #000000;
  font: 400 13px Krub;
  border-radius: 30px;
  width: 60px;
  padding: 4px 0;

  .icon {
    margin-left: 8px;
    width: 19px;
    margin-right: 0;
  }

  &.active {
    flex-direction: row;

    .icon {
      margin-right: 8px;
      width: 19px;
      margin-left: 0;
    }
  }
}
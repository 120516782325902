.swap-auth-container {
  display: flex;

  .has-account-no-account-text {
    font-size: 12px;
  }

  .auth-switch-button {
    margin-left: 0.5rem;
    color: #5200FF;
    font-weight: 700;
    font-size: 12px;
  }
}
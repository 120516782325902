.my-tasks-page {
  padding-top: 80px;

  @media (max-width: 820px) {
    padding-top: 20px;
  }

  .my-tasks {
    display: flex;
    margin: 20px 40px;
    flex-flow: column;

    @media (max-width: 480px) {
      margin: 20px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .title-text {
        align-self: flex-start;
        font: 500 32px Krub;
      }

      .ellipsis-dropdown-container {
        display: none;
      }

      @media (max-width: 800px) {
        width: 100%;
        justify-content: space-between;

        .ellipsis-dropdown-container {
          display: flex;
          align-self: flex-end;
          position: relative;
          top: -10px;

          .ellipsis-dropdown {
            .dropdown-list {
              left: -130px;
              top: 20px;

              .dropdown-option {
                &.selected {
                  .dropdown-selection {
                    font-weight: 800;
                  }
                }
              }
            }
          }
        }
      }
    }

    .subtitle {
      font: 400 22px Krub;
    }
  }

  .task-columns {
    display: flex;
    flex-flow: row wrap;
    
    .task-column {
      margin-right: 60px;
      margin-top: 30px;

      @media (max-width: 1260px) {
        margin-right: 30px;
      }
    }

    @media (max-width: 800px) {

      .task-column {
        margin-top: 10px;
      }

      .task-column.incomplete {
        display: none;
      }

      &.showing-incomplete {
        .task-column.incomplete {
          display: flex;
        }

        .task-column.completed {
          display: none;
        }
      }
    }
  }
}
.campaign-row {
  display: flex;
  border: 2px solid #000000;
  padding: 20px 0px;
  align-items: center;

  &.draft {
    background: #F0EFEF;
    
    .row-view-button {
      .view-button {
        background: #FFFFFF;
        border: 2px solid #000000;
        color: #000000;

        &:hover {
          background: #EAEAEA;
        }
      }
    }
  }

  &.published {
    background: #FFFFFF;

    .row-view-button {
      .view-button {
        background: #67CE67;
        border: 2px solid #67CE67;
        color: #FFFFFF;

        &:hover {
          filter: brightness(95%);
        }
      }
    }
  }

  .row-name {
    flex: 2;
    display: flex;
    text-align: center;
    margin: 0 20px 0 15px;
    flex-flow: wrap;
    font: 500 16px Krub;
    justify-content: center;
  }

  .row-date {
    flex: 1;
    margin-right: 10px;
    font: 400 16px Krub;
  }

  .row-description {
    display: flex;
    flex: 4;
    margin-right: 10px;
    flex-flow: wrap;
    text-align: left;
    font: 400 15px Krub;
  }

  .channels {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    gap: 5px;
    margin: 0 15px;

    .channel {
      width: 32px;
    }
  }

  .row-variant-count {
    flex: 1;
    font: 300 16px Krub;
  }

  .row-view-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .view-button {
      border: 2px solid #000000;
      width: 110px;
      padding: 7px 0;
      border-radius: 30px;
      font: 500 14px Krub;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .row-ellipsis-button {
    max-width: 50px;
    margin-right: 30px;

    .ellipsis-dropdown-container {
      .ellipsis-dropdown {
        .dropdown-list {
          left: -130px;
          top: 5px;

          .dropdown-option {
            .dropdown-selection {
              width: 165px;
            }
          }
        }
      }
    }
  }
}
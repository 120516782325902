.data-field-engagement {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  position: relative;

  .data-field {
    background-color: #F0EFEF;
    color: #000000;
    padding: 3px 8px;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 14px;
    text-wrap: nowrap;
    width: 100%;

    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.available {
      background-color: #A988FC;
      color: #FFFFFF;
    }

    &.full-width-variable {
      max-width: unset;
    }

    &.none-required {
      background-color: transparent;
    }
  }

  .additional-data-required {
    font-size: 12px;
    background-color: transparent;
  }
}
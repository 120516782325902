.sort-dropdown-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background-color: #FFFFFF;
  max-height: 235px;
  position: relative;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #FFFFFF;

    // font-size: 14px;
    // font-weight: 600;
    // color: #000000;

    // margin-bottom: 1px;
    // padding: 6px 10px;
    // box-sizing: border-box;
    
    // background-color: #F0EFEF;
    // border-radius: 5px;
    // border: 1px solid #231F20;
    
    // transition: all 0.25s ease;
    // z-index: 1;

    // cursor: pointer;

    // @media (max-width: 360px) {
    //   font-size: 12px;
    //   padding: 15px;
    // }

    // .dropdown-display-text {
    //   font-size: 12px;
    // }

    .filter-icon {
      height: 25px;
      width: 25px;
    }
  }

  .rec-header-icon {
    transition: all 0.2s;

    &.open {
      transform: rotate(-180deg);
      transition: all 0.2s;
    }
  }

  .dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow: hidden;
    font-size: 12px;
    opacity: 1;
    position: absolute;
    z-index: 2;
    background: #FFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &.open {
      // overflow: unset;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-answer {
      box-sizing: border-box;
      width: 200px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .dropdown-content > span {
    display: inline-block;
  }
}
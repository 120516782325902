.button-with-border {
  display: flex;
  justify-content: center;
  align-items: center;
  
  box-sizing: border-box;
  border-radius: 20px;

  height: 27px;
  width: 145px;
  max-width: 145px;
  min-width: 145px;
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px;

  &:last-child {
    margin-right: 0px;
  }
    
  @media (max-width: 1080px) {
    max-width: 135px;
    min-width: 135px;
    width: 135px;
  }

  @media (max-width: 720px) {
    max-width: 125px;
    min-width: 125px;
    width: 125px;
    font-size: 10px;
  }

  @media (max-width: 600px) {
    max-width: 115px;
    min-width: 115px;
    width: 115px;
  }

  @media (max-width: 480px) {
    max-width: 105px;
    min-width: 105px;
    width: 105px;
    font-size: 9px;
  }

  @media (max-width: 420px) {
    max-width: 100px;
    min-width: 100px;
    width: 100px; 
  }

  &.part-time {
    background-color: #FCFBB2;
    border: 2px solid #F2EFAC;
  }

  &.full-time {
    background-color: #B5FCB7;
    border: 2px solid #A5F3A8;
  }

  &.casual {
    background-color: #A48EDC;
    border: 2px solid #895EF6;
  }

  &.style-role-one {
    background-color: #FCFBB2;
    border: 2px solid #C6B63B;
  }

  &.style-role-two {
    background-color: #F2EFAC;
    border: 2px solid #7A8784;
  }

  &.style-role-three {
    background-color: #B5FCB7;
    border: 2px solid #4CD60B;
  }

  &.style-role-four {
    background-color: #66D771;
    border: 2px solid #128C7E;
  }

  &.style-role-five {
    background-color: #EAE0FF;
    border: 2px solid #5200FF;
  }

  &.style-role-six {
    background-color: #B08EFF;
    border: 2px solid #5E43A5;
    color: #FFFFFF;
  }

  &.view-store {
    background-color: #EAE0FF;
    border: 2px solid #5E43A5;
  }

  &.apply {
    background-color: #8360ED;
    border: 2px solid #5E43A5;
    color: #FFFFFF;
  }

  &.apply-modal-button {
    background-color: #000000;
    color: #FFFFFF;
    width: fit-content;
    min-width: fit-content;
    padding: 0 1rem;
  }

  &.review-options {
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #000000;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    white-space: normal;

    height: 30px;

    &.selected-review-option {
      background-color: #67CE67;
    }
  }

  &.review-modal-proceed {
    background-color: #5200FF;
    color: #FFFFFF;
    border: 2px solid #000000;
    padding: 1rem 2rem;
    margin: 1rem 0;
    font-size: 16px;

    @media (max-width: 1080px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &.disabled {
    cursor: default;
    background-color: #d3d3d3;
    opacity: 0.6;
  }

  &.login-button {
    border-color: #231F20;
    background-color: #231F20;
    color: #FFFFFF;
    font-size: 14px;
  }

  &.signup-logout-button {
    border-color: #231F20;
    background-color: #FFFFFF;
    font-size: 14px;
  }

  &.invite-friends {
    background-color: #5200FF;
    border: 2px solid #000000;
    color: #FFFFFF;
    margin: 1rem 0;
    padding: 1rem 4rem;
  }
  
  &.new-broadcast-button {
    width: unset;
    max-width: unset;
    min-width: unset;

    border-radius: 100px;
    border: 1px solid #000;
    background-color: #FFF;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);

    font-weight: 600;
    padding: 0 1rem;
    margin: 0;
  }

  &.white-bg {
    border: 2px solid #000000;
    background-color: #FFFFFF;
  }

  &.purple-bg {
    background-color: #5200FF;
    border: 2px solid #000000;
    color: #FFFFFF;
  }

  &.xl-button {
    padding: 15px;
    min-width: 170px;
  }

  &.absolute-centered {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100px;
  }
}
.edit-automation-action {
  border: 2px solid #000000;

  .action-details {
    margin-bottom: 50px;
    padding: 0 25px;

    .action-detail {
      .label {
        margin: 25px 0 8px 0;
        font: 400 16px Krub;
        font-style: italic;
      }

      .edit-automation-input-container {
        margin-top: 0px;
      }
    }
  
  }
}
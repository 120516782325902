.work-rex-modal-container.attachment-modal {
  .work-rex-modal {
    background: #FFFFFF;

    .close-modal-button {
      top: -16px;
      right: 20px;
      width: 0;
      height: 0;

      &:hover {
        transform: scale(1);
      }
    }

    .work-rex-modal-content {
      display: flex;
      margin: 0px 20px 0 20px;
      padding: 10px 20px 20px 20px;

      .modal-description {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-bottom: 20px;

        .attachment-modal-title {
          font: 600 18px Krub;
          margin-bottom: 5px;
        }

        .description {
          font: 300 14px Krub;
          font-style: italic;
          margin-bottom: 5px;
        }
      }

      .attachment-container {
        border: dashed 2px #000000;
        width: fit-content;
        display: flex;
        flex-flow: column;
        padding: 20px 40px;
        align-items: center;

        .error-text {
          text-align: center;
          font-size: 14px;
        }

        &.dragging {
          background: #EFEFEF;
        }

        .attachment-input {
          visibility: hidden;
          height: 0;
        }

        .attachment-input-label {
          margin: 15px 0 10px 0;
          border-radius: 50px;
          padding: 6px 20px;
          border: solid 2px #000000;
          font: 500 16px Krub;
          width: 100px;
          text-align: center;

          &:hover {
            cursor: pointer;
            background: #EFEFEF;
          }
        }

        .input-tip {
          margin: 5px 0 10px 0;
          font: 300 14px Krub;
          font-style: italic;
        }
      }

      .upload-button {
        margin-top: 20px;
        border-radius: 50px;
        padding: 6px 20px;
        border: solid 2px #000000;
        background: #000000;
        color: #FFFFFF;
        font: 500 14px Krub;
        width: 120px;

        &:hover {
          opacity: 0.8;
        }

        &.loading {
          opacity: 0.8;

          &:hover {
            cursor: auto;
          }
        }
      }
    }
  }
}
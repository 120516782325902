.automation-list {
  display: grid;
  margin: 0 10px;
  grid-template-columns: repeat(auto-fill, 440px);
  width: 100%;
  overflow: auto;
  max-height: calc(540px + 20vh);

  @media (max-height: 980px) {
    max-height: calc(540px + 16vh);
  }

  @media (max-height: 900px) {
    max-height: calc(490px + 16vh); 
  }

  @media (max-height: 800px) {
    max-height: calc(430px + 12vh); 
  }

  @media (max-height: 700px) {
    max-height: calc(410px + 10vh); 
  }

  @media (max-height: 680px) {
    max-height: 410px;
  }

  .automation-card {
    margin: 20px;
  }
}
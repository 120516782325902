.inbox-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .inbox-control-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    height: 40px;
    padding: 1rem 10px 0;
    box-sizing: border-box;
  
    .inbox-filter-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8px;
      
      .search-icon-button {
        background: transparent;
        height: 25px;
    
        .search-icon {
          height: 25px;
        }
      }
    }
  
    .inbox-select-all-button {
      font-size: 12px;
      white-space: nowrap;
      margin-left: 10px;
    }
  }

  .contact-rows {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .show-more-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
    }
  }
}
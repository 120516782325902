.selection-dropdown-container {
  .selection-dropdown-button {
    padding: 5px 20px;
    border: 2px solid #000000;
    border-radius: 40px;
    outline: none;
    font: 400 15px Krub;
    min-width: 290px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.disabled {
      background: #E5E5E5;

      &:hover {
        cursor: auto;
      }
    }

    .dropdown-arrow {
      transform: rotate(180deg);
    }

    &.open {
      .dropdown-arrow {
        transform: rotate(0deg);
      }
    }
  }

  .dropdown-container {
    height: 0;
    width: 100%;
    position: relative;

    .selection-dropdown-list {
      position: absolute;
      background: #FFFFFF;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      top: 5px;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      border-radius: 0 0 5px 5px;
      z-index: 2;

      .selection-item {
        .selection-item-button {
          padding: 5px 25px;
          width: 100%;
          align-items: flex-start;
          display: flex;
          font: 400 14px Krub;
          background: transparent;
          overflow: hidden;

          &:hover {
            background: #EFEFEF;
          }
        }

        &.selected {
          .selection-item-button {
            font-weight: 600;
          }
        }
      }
    }
  }
}
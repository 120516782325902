.navigation-dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  box-sizing: border-box;
  padding: 0 10px 0 1.5rem;

  margin-top: 10px;
  width: 100%;

  @media (max-width: 820px) {
    padding: 0 10px 0 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .navigation-filter {
    display: flex;
    align-items: center;
    column-gap: 10px;

    .sub-nav-icon {
      width: 12px;
    }
  
    .navigation-dropdown-button-text {
      font-size: 12px;
  
      &.selected {
        font-weight: 700;
      }
    }
  }

  .filter-count {
    font-size: 12px;
    font-weight: 700;
    color: #9F9F9F;
  }
}
.automation-activity-log {
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
  padding: 20px 0;

  .activity-log-title {
    font: 600 20px Krub;
    margin-bottom: 20px;
  }

  .automation-history {
    display: flex;
    flex-flow: column;
    max-height: 480px;
    min-height: 480px;
    overflow: auto;
    padding: 10px 0;
  }
}
.groups-dropdown-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;

  background-color: #FFFFFF;
  max-height: 235px;
  position: relative;

  &.loading { 
    height: 30px;
  }

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    color: #000000;

    margin-bottom: 1px;
    padding: 6px 10px;
    box-sizing: border-box;
    
    background-color: #FFFFFF;
    
    transition: all 0.25s ease;
    z-index: 1;

    cursor: pointer;

    @media (max-width: 360px) {
      font-size: 12px;
      padding: 15px;
    }
  }

  .rec-header-icon {
    transition: all 0.2s;
  }

  .open {
    transform: rotate(-180deg);
    transition: all 0.2s;
  }

  .dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow-y: scroll;
    font-size: 12px;
    opacity: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    width: 100%;
    z-index: 2;
    box-shadow: 1px 1px 10px 0 rgba(0,0,0,.1);

    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-answer {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .dropdown-content > span {
    display: inline-block;
  }
}
.search-icon-button {
  background: transparent;
  height: 25px;

  &.manager-recipients-page-search-button {
    height: 30px;
  }

  .search-icon {
    height: 25px;

    &.manager-recipients-page-search-button {
      height: 30px;
    }
  }
}
@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Krub;
  src: url('./fonts/Krub-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

#root {
  overflow-x: hidden;
  margin: auto;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

html * {
  font-family: Krub;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 100;
}

a {
  text-decoration: none;
  color: #000000;
}

.very-light:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.light:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.dark:hover {
  cursor: pointer;
  filter: brightness(105%);
}

.very-dark:hover {
  cursor: pointer;
  filter: brightness(110%);
}

button {
  border: none;
  color: #000000;
  background-color: #FFFFFF;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  &.loading {
    opacity: 0.4;
    cursor: auto;

    &:hover, &.very-dark:hover, &.very-light:hover, &.dark:hover, &.light:hover {
      cursor: auto;
      filter: brightness(100%);
    }
  }
}

hr {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.no-shifts-text {
  font-size: 36px;
  margin: auto auto;
  color: #b4b1b1;
  padding: 0 10px;
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.green-button {
  background-color: #55A08D;
  color: #FFFFFF;
  padding: 8px 10px;
  border-radius: 100px;
}

.error-text {
  color: red;
  font-size: 20px;
  min-height: 30px;
  display: flex;
  margin: px 0 10px 0px;

  @media (max-width: 1920px) {
    font-size: 19px;
  }

  @media (max-width: 1440px) {
    font-size: 18px;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
    min-height: 25px;
  }

  @media (max-width: 980px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    min-height: 10px;
    font-size: 14px;
  }

  @media (max-width: 420px) {
    font-size: 12px;
  }
}

// Fix for safari input text bug
// See https://stackoverflow.com/questions/32851413/input-field-ios-safari-bug-cant-type-in-any-text
input { 
  -webkit-user-select: text;
}

$toggle-width: 120px;
$toggle-height: 35px;

.automation-active-toggle {
  display: flex;

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
    z-index: 1;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $toggle-width;
    height: $toggle-height;;
    background: #FFFFFF;
    display: block;
    border-radius: 100px;
    position: relative;
    border: 2px solid #000000;

    &.active:after {
      background: #67CE67;
    }
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    width: calc($toggle-height - 15px);
    height: calc($toggle-height - 15px);
    background: #F2B603;
    border-radius: 90px;
    transition: 0.3s;
  }
  
  input:checked + label {
    background: #FFFFFF;
  }
  
  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }
  
  label:active:after {
    width: $toggle-width - 10px;
  }

  .text-container {
    height: 0;
    width: 0;
    position: absolute;
    z-index: 2;

    .text {
      display: flex;
      width: 80px;
      position: relative;
      font: 600 14px Krub;
      background: transparent;
    }

    &.inactive {
      margin-left: 10px;
      .text {
        left: calc($toggle-width - 78px);
        top: calc($toggle-height - 25px);
      }
    }

    &.active {
      .text {
        left: 20px;
        top: calc($toggle-height - 25px);
      }
    }
  }
}
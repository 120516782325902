.modal-container-wrapper {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;
  background-color: rgba(35, 31, 32, 0.45);
  
  .modal-container {
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 600px;

    @media (max-width: 720px) {
      width: 540px;
    }

    @media (max-width: 600px) {
      width: 450px;
    }

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 300px;
    }

    @media (max-width: 360px) {
      width: 280px;
    }

    &.show-details {
      width: 800px;
      border-radius: 0px;
      border: 2px solid #000000;

      @media (max-width: 720px) {
        width: 640px;
      }
  
      @media (max-width: 600px) {
        width: 520px;
      }
  
      @media (max-width: 480px) {
        width: 420px;
      }
  
      @media (max-width: 420px) {
        width: 360px;
      }
  
      @media (max-width: 360px) {
        width: 320px;
      }
    }

    &.view-message {
      border-radius: 0px;
      border: 2px solid #000000;
    }

    &.archive {
      background-color: #FEFFD8;
      border-radius: 0px;
      border: 2px solid #000000;
    }
    
    &.new-broadcast {
      border: 2px solid #000000;

      width: 500px;
      height: 500px;

      @media (max-width: 720px) {
        width: 440px;
        height: 440px;
      }

      @media (max-width: 600px) {
        width: 350px;
        height: 350px;
      }

      @media (max-width: 480px) {
        width: 280px;
        height: 280px;
      }
    }

    &.confirm-broadcast {
      width: 500px;
      height: fit-content;

      @media (max-width: 720px) {
        width: 440px;
        height: fit-content;
      }

      @media (max-width: 600px) {
        width: 350px;
        height: fit-content;
      }

      @media (max-width: 480px) {
        width: 280px;
        height: fit-content;
      }
    }

    &.groups {
      height: 300px;
      width: 500px;

      @media (max-width: 720px) {
        width: 440px;
        height: fit-content;
      }

      @media (max-width: 600px) {
        width: 350px;
        height: fit-content;
      }

      @media (max-width: 480px) {
        width: 280px;
        height: fit-content;
      }
    }

    .close-modal-button-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .close-modal-button {
        color: #B5B5B5;
        font: 600 30px Inter, Helvetica, Arial, sans-serif;
        position: relative;
        background: transparent;
        right: 5px;
        top: -5px;

        @media (max-width: 600px) {
          font-size: 20px;
        }

        .close-modal-icon {
          width: 15px;
          filter: brightness(0);
        }
      }
    }
  }
}
.analytics-overflow-container {
  border: solid 2px #000000;
  position: relative;
  top: -2px;
}

.analytics-table-container {
  overflow-x: auto;
  display: flex;
  flex-flow: column;
  
  .analytics-table {
    width: 100%;
    min-width: 620px;

    // white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;

    .table-header {
      text-align: center;
      position: sticky;
      top: 0;
      background-color: #EFEEEC;
      z-index: 2;
  
      .table-row {
        .table-header-cell {
          position: sticky;
          top: 0;
          z-index: 1;
          width: 25vw;
          background-color: #EFEEEC;

          height: 50px;
          padding-left: 25px;
          text-align: left;

          &:first-child {
            position: sticky;
            left: 0;
            z-index: 2;

            &.scrolling {
              border-right: solid 1px #efeeec;
            }
          }
  
          .table-item {
            font: 600 15px Krub;
          }
        }
      }
    }

    tbody {
      .table-row {
        padding: 10px;
        text-align: center;
        height: 50px;

        &.hovering {
          background-color: #EAE8FA;
        }

        .table-row-channel-cell {
          font-weight: 400;
          color: #9F9F9F;
          text-align: left;
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 1;
          padding: 5px 25px;
          min-width: 140px;

          .channel-analytic {
            margin-left: 1rem;
          }

          &.last-channel {
            border-bottom: solid 1px #efeeec;
          }
        }

        .table-row-header-cell {
          text-align: left;
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 1;
          padding: 5px 25px;
          min-width: 140px;
          border-bottom: 1px solid #efe3e3;
          border-right: solid 1px #efeeec;

          &.hovering {
            background-color: #EAE8FA;
          }

          &.clicked-highlight {
            background-color: #EAE8FA;
          }

          &.display-channels {
            border-bottom: none;
            border-right: none;
          }
        }

        .table-cell {
          padding: 0 25px;
          min-width: 140px;
          text-align: left;
          border-bottom: 1px solid #efe3e3;

          &.hide-borders {
            border: none;
          }

          &.not-last-channel {
            border-bottom: none;
          }

          &.clicked-highlight {
            background-color: #EAE8FA;
          }
          
          .cell-text {
            font: 400 15px Krub;
            min-width: 110px;
            min-height: 20.5px;
            text-align: left;
          }

          .group-cell-text {
            font: 400 15px Krub;
            min-width: 110px;
            min-height: 20.5px;
            text-align: left;
            white-space: nowrap;
            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          
          .text-input {
            text-align: center;
            width: calc(100% - 20px);
            font-size: 15px;
          }
        }
      }
    }
  }

  .no-rows-text {
    align-self: center;
    margin-top: 20px;
    text-align: center;
  }
}
.channel-dropdown-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 235px;
  position: relative;
  width: 150px;
  min-width: 150px;

  .channel-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;

    background-color: #FFFFFF;
    font-size: 12px;
    color: #000000;

    margin-bottom: 1px;
    padding: 2px 10px;
    box-sizing: border-box;
    
    transition: all 0.25s ease;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 26px;

    cursor: pointer;
    height: 25px;
    width: 100%;

    @media (max-width: 360px) {
      font-size: 12px;
      padding: 15px;
    }

    .channel-details {
      display: flex;
      column-gap: 10px;

      .channel-icon {
        width: 15px;
      }

      .channel-dropdown-title {
        color: #9F9F9F;
      }
    }

    .rec-header-icon {
      transform: rotate(-90deg);
      transition: all 0.2s;
      height: 10px;
      margin-right: 3px;
      filter: invert(70%) sepia(9%) saturate(0%) hue-rotate(177deg) brightness(88%) contrast(98%);
    }
  
    .open {
      transform: rotate(90deg);
      transition: all 0.2s;
    }
  }

  .channel-dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow-y: scroll;
    font-size: 12px;
    opacity: 1;
    border-radius: 10px;
    position: absolute;
    width: 100%;
    z-index: 2;

    &::-webkit-scrollbar {
      display: none;
    }

    .channel-dropdown-answer {
      border-radius: 10px;
      padding: 2px 0;
      box-sizing: border-box;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .dropdown-content > span {
    display: inline-block;
  }
}
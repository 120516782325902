.automation-request-examples-container {
  .view-examples-toggle {
    font: 500 16px Krub;
    text-decoration: underline;
    text-underline-position: under;

    &:hover {
      opacity: 0.6;
    }
  }

  .example-list {
    margin: 10px 30px 0 30px;
    list-style: url('../../assets/example-bullet.svg') outside;
    display: flex;
    flex-flow: column;
    
    .example-item {
      padding: 8px 10px;
    }
  }
}
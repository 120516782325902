.editing-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 17px 26px 0px rgba(0, 0, 0, 0.10);
  padding: 1rem;
  box-sizing: border-box;
  position: absolute;

  top: 10px;
  left: 10px;

  width: 160px;

  z-index: 2;

  &.engagement-item-dropdown {
    left: auto;
    right: 15px;
    top: -25px;
    width: 120px;
    padding: 12px;
  }

  &.left-align {
    right: 10px;
    left: unset;
  }

  &.chat-message-modal {
    bottom: 10px;
    top: unset;
  }

  .edit-button {
    margin: 5px 0;

    &:hover {
      font-weight: 700;
    }

    &:active {
      font-weight: 700;
    }
  }
  
  &.data-field-popup {
    row-gap: 10px;
    bottom: 40px;
    top: unset;
    left: unset;
    width: unset;
  }

  &.engagement-hover {
    background-color: #EAE0FF;
    box-shadow: 5px 4px 150px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    bottom: 50px;
    top: unset;
    left: unset;
    width: 240px;
  }

  &.first-engagement {
    bottom: unset;
    top: 50px;
  }
}
.all-engagements-page {
  display: flex;
  margin-top: 40px;
  height: 100%;
  border-radius: 30px;

  .all-engagements {
    width: 100%;
    height: 100%;
    padding: 0 0 2rem;
    box-sizing: border-box;
    margin-left: 30px;
      margin-top: 10px;

    .all-engagements-display {
      .all-engagements-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        height: 30px;
    
        .all-engagements-title {
          font: 500 18px Krub;
        }
    
        .show-available-section {
          display: flex;
          align-items: center;
    
          .available-section-title {
            font: 500 16px Krub;
            margin-right: 15px;
          }
        }
      }
    
      .engagement-opportunities {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        font-size: 14px;

        max-height: calc(500px + 20vh);

        @media (max-height: 980px) {
          max-height: calc(500px + 16vh);
        }

        @media (max-height: 900px) {
          max-height: calc(450px + 16vh); 
        }

        @media (max-height: 800px) {
          max-height: calc(420px + 12vh); 
        }

        @media (max-height: 700px) {
          max-height: calc(400px + 10vh); 
        }

        @media (max-height: 680px) {
          max-height: 410px;
        }

        overflow: auto;
        position: relative;
        top: -20px;
      }
    
      .all-assistants-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
      
        .loading-container {
          height: 30px;
          width: 165px;
        }
      }
    }
  }
}
.broadcast-page {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  padding: 1rem;
  box-sizing: border-box;

  .broadcast-title {
    font-weight: 600;
  }

  .broadcast-field-container {
    display: flex;
    flex-direction: column;

    .broadcast-field-label {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 1rem;
    }

    .broadcast-field-value {
      font-weight: 600;
    }

    .broadcast-input-box {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .new-broadcast-template {
      resize: none;
      font-weight: 600;
    }

    .schedule-broadcast-send {
      border: 2px solid #000;
      background: #FFF;
      padding: 5px;
      box-sizing: border-box;
      text-align: left;
    }
  }

  .broadcast-button-container {
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 1rem;

    .broadcast-button {
      border-radius: 30px;
      border: 1px solid #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      padding: 4px 15px;
      font-weight: 600;

      &.delete-broadcast {
        background-color: #FFFFFF;
      }

      &.pause-broadcast {
        background-color: #FEFFD8;
      }
    }
  }

  .broadcast-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-sizing: border-box;

    .broadcast-modal-text {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2rem;
      text-align: center;
    }

    .broadcast-modal-button {
      border-radius: 30px;
      border: 2px solid #231F20;
      background: #231F20;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
      padding: 5px 20px;
      box-sizing: border-box;
    }
  }
}
.search-contacts {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  border: 2px solid #9F9F9F;
  background: #FFFFFF;

  .search-label {
    font-size: 12px;
    color: #9C9C9C;
  }

  .recipient-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: scroll;
    column-gap: 1rem;

    .searched-contact {
      font-weight: 600;
      font-size: 12px;
      padding: 5px 10px;
      box-sizing: border-box;

      &:hover {
        border-radius: 100px;
        background-color: #EDEDED;
      }

      &.selected {
        border-radius: 100px;
        background-color: #EDEDED;
      }
    }
  }
}
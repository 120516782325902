.create-channels-display {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px 50px 40px 50px;
  margin: 0 auto 30px auto;
  max-width: 1560px;
  border-radius: 30px;
  margin-top: 10px;
  z-index: 3;
  position: relative;
  min-height: 500px;

  .creation-form {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      justify-content: space-between;
      width: 100%;

      .header-buttons {
        display: flex;

        .back-button {
          font: 500 15px Krub;
          width: 110px;
          text-align: center;
          padding: 6px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          border-radius: 30px;
          background: #FFFFFF;
          color: #000000;
          border: 2px solid #000000;
          margin-right: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    
          &:hover {
            background: #F1F1F1;
          }
        }

        .draft-button {
          font: 500 15px Krub;
          width: 130px;
          text-align: center;
          padding: 6px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          border-radius: 30px;
          background: #FEFFD8;
          color: #000000;
          border: 2px solid #000000;
          margin-right: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    
          &:hover {
            filter: brightness(95%);
          }
        }

        .publish-button {
          font: 500 15px Krub;
          width: 130px;
          text-align: center;
          padding: 6px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          border-radius: 30px;
          background: #5200FF;
          color: #FFFFFF;
          border: 2px solid #000000;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
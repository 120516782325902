.voicemail {
  margin-top: 10px;

  .upload-button {
    border: 2px solid #000000;
    border-radius: 25px;
    background: #FFFFFF;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 500 16px Krub;
    padding: 11px 0;
    outline: none;

    .upload-icon {
      margin-right: 10px;
      width: 17px;
    }

    &:hover {
      background: #F5F5F5;
    }
  }

  .voicemail-message-input {
    margin: 40px 0 10px 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    .voicemail-icon {
      max-width: 100%;
    }

    .play-button-container {
      position: relative;
      top: -105px;
      height: 40px;
    }

    .play-button {
      background: transparent;

      &:hover {
        filter: brightness(0.85);
      }
    }
  }

  .submit-button {
    align-self: flex-end;
    font: 500 16px Krub;
    background: #5200FF;
    border: 2px solid #000000;
    color: #FFFFFF;
    padding: 10px 45px;
    border-radius: 50px;

    &:hover {
      opacity: 0.8;
    }
  }
}
.work-rex-modal-container.campaign-selection-modal {
  .work-rex-modal {
    background: #FFFFFF;

    .close-modal-button {
      top: -16px;
      right: 20px;
      width: 0;
      height: 0;

      &:hover {
        transform: scale(1);
      }
    }

    .work-rex-modal-content {
      display: flex;
      margin: 0px 20px 0 20px;
      padding: 10px 20px 20px 20px;
      flex-flow: column;
      align-items: center;

      .campaign-selection-title {
        font: 600 18px Krub;
        margin-bottom: 20px;
      }

      .search-campaigns-container {
        .search-dropdown-container {
          min-width: 330px;
        }

        .selected-campaign-text {
          min-height: 40px;
          margin: 20px 0;
          width: fit-content;

          .text {
            display: flex;
            padding: 6px 12px;
            background: #A988FC;
            align-items: center;
            color: #FFFFFF;
            font: 500 16px Krub;
            border-radius: 30px;

            .remove-button {
              margin-left: 15px;
              background: transparent;
              color: #FFFFFF;
              font: 300 16px Krub;
              transform: scale(2);
              position: relative;
              top: -1px;
              margin-right: 3px;

              &:hover {
                transform: scale(2.4);
                opacity: 0.8;
              }
            }
          }
        }
      }

      .confirm-buttons {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-top: 20px;

        .back-button {
          font: 500 14px Krub;
          background: #FFFFFF;
          border: 1.5px solid #000000;
          color: #000000;
          padding: 5px 0px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          width: 160px;
          justify-content: center;
          outline: none;
          height: 30px;

          &:hover {
            background: #EFEFEF;
          }
        }

        .confirm-button {
          font: 500 14px Krub;
          background: #5200FF;
          border: 1.5px solid #000000;
          color: #000000;
          padding: 5px 0px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          width: 160px;
          justify-content: center;
          outline: none;
          height: 30px;
          color: #FFFFFF;

          &:hover {
            opacity: 0.8;
          }
        }
      }

    }
  }
}
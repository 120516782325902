.interaction-card {
  display: flex;
  width: 100%;
  position: relative;

  &:hover .interaction-details > .interaction-heading > .interaction-header-container > .edit-selected-button {
    display: flex;
    align-items: center;
    position: relative;
  }

  .interaction-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;

    .interaction-type-icon {
      width: 25px;
      height: 25px;
    }
  }

  .interaction-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100%;
    row-gap: 4px;

    .interaction-heading {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 600;

      .interaction-header-container {
        display: flex;
        justify-content: flex-start;
        column-gap: 5px;

        .interaction-label {
          
        }

        .edit-selected-button {
          display: none;
    
          .edit-icon {
    
          }
        }
      }

      .interaction-start {
        
      }
    }

    .interaction-creation-details {
      display: flex;
      column-gap: 15px;
    }

    .interaction-label-container {
      text-align: left;

      .interaction-label {
        text-align: left;

        .interaction-label-text {
          font-style: italic;
        }

        .see-more-button {
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
  }

  .archive-interaction-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-sizing: border-box;

    .archive-text {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2rem;
    }

    .confirm-archive {
      border-radius: 30px;
      border: 2px solid #231F20;
      background: #231F20;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
      padding: 5px 20px;
      box-sizing: border-box;
    }
  }
}
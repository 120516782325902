.text-input {
  width: 100%;
  max-width: 480px;
  font: 400 16px Krub;
  background: #F6F5F5;
  padding: 12px 15px; 
  border: none;

  &.disabled {
    background-color: #FFFFFF;
  }
}
.team-picker-container {
  display: flex;
  align-items: center;

  .plus-button {
    width: 260px;
    background: #FEFFD8;
  }
  
  .team-list {
    display: flex;
    margin-left: 15px;

    .team-member {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-right: 20px;

      .initials {
        background: #5200FF;
        color: #FFFFFF;
        width: 28px;
        height: 28px;
        font: 500 12px Krub;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .name {
        font: 600 12px Krub;
        margin-top: 2px;
      }

      .delete-button-container {
        position: relative;
        width: 0;
        height: 0;
        
        .delete-button {
          top: -57px;
          left: 10px;
          width: 15px;
          height: 15px;
          font-size: 15px;
        }


      }
    }
  }
}
.edit-automation-dropdown-container {
  .dropdown-button {
    display: flex;
    justify-content: space-between;
    padding: 25px 25px;
    width: 100%;
    align-items: center;

    .text {
      font: 500 18px Krub;
    }
  }

  &.open {
    .caret-icon {
      transform: rotate(180deg);
    }
  }
}
.chat-room {
  width: 100%;
  height: calc(100% - 70px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .attachments-container {
    height: 0;
    position: absolute;
  
    .attachments {
      display: flex;
      flex-direction: column-reverse;
      height: auto;
      position: relative;
      bottom: 25px;
    }
  }
  
  .attachment {
    font-size: 12px;
    background-color: #D7D9DD;
    padding: 5px;
    align-self: flex-start;
  
    .delete-container {
      height: 0;
      width: 0;
      position: absolute;
  
      .delete {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        width: 20px;
        height: 20px;
        border-radius: 200px;
        text-align: center;
        position: relative;
        top: -15px;
        left: -5px;
        background-color: #FFFFFF;
  
        &:hover {
          cursor: pointer;
          filter: brightness(90%);
        }
      }
    }
  }
}
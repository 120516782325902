.variable-text-suggestions {
  position: relative;
  width: 0;
  height: 0;
  z-index: 2;

  &.key-movement-disabled {
    .variable-list-container {
      width: 230px;
      padding-left: 5px;
      border-radius: 5px;

      .variable-list {
        max-height: 150px;
        padding: 5px 0;
      }
    }
  }

  .variable-list-container {
    position: relative;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 5px 0;
    border-radius: 20px;
    width: 200px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-flow: column;

    .variable-list {
      max-height: 120px;
      background: transparent;
      overflow: auto;
      align-self: flex-start;
      width: 100%;

      .variable-name {
        .variable-selection {
          width: 100%;
          font: 400 15px Krub;
          text-align: left;
          background: transparent;
          padding: 6px 10px;

          .variable {
            background: #A988FC;
            color: #FFFFFF;
            padding: 2px 10px;
            border-radius: 50px;
            font-size: 14px;
            text-wrap: nowrap;
            border: solid 2px transparent;
          }

          &:hover {
            filter: brightness(110%);
          }
        }

        &.selected {
          .variable-selection {
            .variable {
              border: solid 2px #5200FF;
            }
          }
        }
      }
    }

    .variable-select-text {
      font-size: 14px;
      font-style: italic;
      padding: 5px;
      text-align: center;
      display: flex;
    }
  }
}
.mobile-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1rem;
  background-color: #5200ff;
  height: 60px;

  .logo-container {
    display: flex;

    .logo-button {
      background-color: transparent;
      
      .workrex-logo {
        width: 120px;
      }
    }
  }

  .mobile-navbar-hamburger {
    min-width: 22px;
    border: none;
    background-color: initial;
  }
}
.file-preview {
  display: flex;
  flex-flow: column;
  margin-right: 60px;
  
  .preview {
    margin-top: 40px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-width: 360px;
    min-height: 400px;
    padding: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .preview-image {
      max-width: 280px;
      max-height: 280px;

      &.default {
        height: 100px;
        width: 100px;
      }
    }

    .preview-title {
      font: 500 18px Krub;
      margin-top: 30px;
    }
  }
}
.toggle-input-container {
  margin-top: 8px;
  min-width: 420px;

  .toggle-input-label {
    font: 400 16px Krub;
    margin-left: 10px;
  }

  .toggle-input {
    transform: scale(1.4);
  }
}
.task-history-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  column-gap: 5px;

  .task-history-date {
    font-weight: 400;
    text-align: left;
    flex: 1;
  }

  .task-history-description {
    font-weight: 500;
    text-align: center;
    flex: 3;
  }

  .task-history-user {
    font-weight: 500;
    text-align: right;
    flex: 1;
  }
}
.task-card {
  display: flex;
  align-items: center;
  width: 95%;

  @media (max-width: 820px) {
    width: 100%;
  }

  .task-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;

    @media (max-width: 820px) {
      padding: 0;
    }

    .task-type-icon {
      width: 25px;
      height: 25px;

      @media (max-width: 820px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  .task-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100%;
    row-gap: 4px;

    .task-heading {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 14px;
      font-weight: 600;

      .task-due {
        font-size: 14px;
        width: 80px;
        min-width: 80px;
        text-align: left;

        @media (max-width: 820px) {
          font-size: 13px;
          width: 70px;
          min-width: 70px;
        }
      }

      .task-label {
        text-align: left;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .task-assigned-details {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
}
.search-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .search-controls-container {
    display: flex;
    align-items: center;
    padding: 1rem 10px 0;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
  }
  
  .search-results {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
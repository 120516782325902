.task-list-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .task-header {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    padding: 5px;
    box-sizing: border-box;

    .select-all-button {
      font-size: 12px;
    }

    .edit-selected-button {
      display: flex;
      align-items: center;
      position: relative;

      .edit-icon {

      }
    }
  }

  .task-rows {
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .archive-task-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-sizing: border-box;

    .archive-text {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2rem;
    }

    .confirm-archive {
      border-radius: 30px;
      border: 2px solid #231F20;
      background: #231F20;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
      padding: 5px 20px;
      box-sizing: border-box;
    }
  }
}
.channel-preview-container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;

  .channel-preview {
    border: 2px solid #000000;
    width: calc(100% - 10px);
    min-height: 440px;
    padding: 20px 50px;
  }

  .preview-wrapper {
    margin-top: 15px;

    .preview-title {
      font: 500 20px Krub;
      margin-bottom: 5px;
    }

    .preview-content {
      .preview-editor {
        .direct-message {
          .message-container {
            .message-label {
              font: 400 16px Krub;
            }
          }
        }
      }
    }
  }
}
.add-button {
  padding: 10px 20px;
  border: 2px solid #000000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 220px;

  .text {
    font: 500 15px Krub;
  }

  .plus-icon {
    width: 14px;
  }

  &:hover {
    background: #F3F3F3;
    opacity: 0.9;
  }
}
.integration-modal-container.request-integration-modal {
  .integration-modal {
    .integration-modal-content {
      display: flex;
      align-items: center;
      flex-flow: column;

      .modal-title {
        font: 700 22px Krub;
        text-align: center;
        margin-bottom: 20px;
  
        @media (max-width: 420px) {
          font-size: 20px;
        }
      }

      .integration-name {
        padding: 10px 10px;
        font: 400 15px Krub;
        min-width: 540px;
        border: 2px solid #000000;
        margin-top: 20px;

        @media (max-width: 660px) {
          min-width: 420px;
        }

        @media (max-width: 480px) {
          min-width: 300px;
          font: 400 12px Krub;
        }

        @media (max-width: 420px) {
          min-width: 260px;
        }
      }

      .confirm-button {
        padding: 12px 40px;
        background: #000000;
        color: #FFFFFF;
        border-radius: 50px;
        font: 500 16px Krub;
        margin-top: 40px;
        margin-bottom: 10px;
  
        &:hover {
          cursor: pointer;
          background: #2a2a2a;
        }
  
        &.disabled {
          opacity: 0.6;
          cursor: auto;
  
          &:hover {
            background: #000000;
          }
        }
      }
    }
  }
}
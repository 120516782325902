.chat-history {
  display: flex;
  flex-direction: column;
  height: 70%;
  margin-bottom: 20px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 30px;
  row-gap: 1rem;

  @media (max-width: 1240px) {
    padding: 20px;
  }

  @media(max-width: 980px) {
    padding: unset;
    background-color: #FFFFFF;
    border-radius: unset;
    height: 100%;
  }

  .chat-receiver-name {
    display: flex;
    margin: 0px 25px;
    font-size: 12px;
    font-weight: 300;
  }

  .chat-datestamp-container {
    position: relative;
    margin-bottom: 1rem;

    .horizontal-divider {
      width: 50%;
      opacity: 0.5;
    }

    .chat-datestamp {
      text-align: center;
      color: #5200FF;
      font-size: 14px;
      display: flex;
      align-self: center;
      font-weight: 400;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background-color: #FFFFFF;
      padding: 0 20px;
    }
  }
  
  .chat-msg-container {
    display: flex;
    flex-direction: column;

    &:hover .edit-selected-button {
      display: flex;
      align-items: center;
      position: relative;
    }

    &:hover .chat-msg-body {
      margin-bottom: 0;
    }

    @media (max-width: 420px) {
      flex-direction: column;
    }

    .edit-selected-button {
      display: none;
      height: 10px;

      .edit-icon {

      }

      &.receiver {
        margin: 0px auto 0px 20px;

        @media (max-width: 420px) {
          margin: 0 auto 0 20px; 
          font-size: 10px;
        }
      }

      &.sender {
        margin: 0px 20px 0px auto;

        @media (max-width: 420px) {
          margin: 0 20px 0 auto;
          font-size: 10px;
        }
      }
    }
    
    .chat-msg-body {
      display: flex;
      white-space: pre-wrap;
      margin-bottom: 10px;

      .chat-timestamp {
        margin: 10px auto;
        text-align: center;
        color: #5200FF;
        font-size: 12px;
        display: flex;
        align-self: center;

        &.receiver {
          margin: 10px auto 10px 0;
  
          @media (max-width: 420px) {
            margin: 0 auto 0 20px; 
            font-size: 10px;
          }
        }
  
        &.sender {
          margin: 10px 0 10px auto;
  
          @media (max-width: 420px) {
            margin: 0 20px 0 auto;
            font-size: 10px;
          }
        }
      }

      .chat-attachment {
        max-width: 400px;
        width: 400px;
        padding: 10px 20px;
        width: fit-content;
        border-radius: 6px;
        line-height: 15px;

        .size {
          margin-left: 10px;
        }

        @media (max-width: 980px) {
          max-width: 320px;
          width: 320px;
        }

        @media (max-width: 800px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 700px) {
          max-width: 240px;
          width: 240px;
        }

        @media (max-width: 600px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 420px) {
          max-width: 240px;
          width: 240px;
          font-size: 14px;
        }

        @media (max-width: 360px) {
          max-width: 220px;
          width: 220px;
        }

        .download-link {
          text-decoration: underline;
          font-weight: 900;
        }

        &.receiver {
          background-color: #CAE3DD; 
          margin: 7px 10px 7px 20px;
          border-radius: 20px 20px 20px 0px;

          @media (max-width: 420px) {
            margin: 7px 10px 0 20px;
          }
        }

        &.sender {
          background-color: #FFFFFF;
          margin: 7px 20px 7px 10px;
          border-radius: 20px 20px 0px 20px;
          order: 1;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 980px) {
            background-color: #F5F5F5;
          }

          @media (max-width: 420px) {
            order: 0;
            align-self: flex-end;
          }
        }
      }

      .chat-message {
        max-width: 400px;
        width: 400px;
        padding: 10px 20px;
        width: fit-content;
        border-radius: 6px;
        line-height: 15px;
        font-weight: 300;

        @media (max-width: 980px) {
          max-width: 320px;
          width: 320px
        }

        @media (max-width: 800px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 700px) {
          max-width: 240px;
          width: 240px;
        }

        @media (max-width: 600px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 420px) {
          max-width: 240px;
          width: 240px;
          font-size: 14px;
        }

        @media (max-width: 360px) {
          max-width: 220px;
          width: 220px;
        }

        &.receiver {
          background-color: #EFEFF1; 
          margin: 7px 10px 7px 20px;
          border-radius: 10px;

          @media (max-width: 420px) {
            margin: 7px 10px 0 20px;
          }
        }

        &.sender {
          background-color: #A988FC;
          color: #FFFFFF;
          margin: 7px 20px 7px 10px;
          border-radius: 10px;
          order: 1;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 420px) {
            order: 0;
            align-self: flex-end;
            margin: 7px 20px 0 10px;
          }
        }
      }
    }
  }
}

.chat-history {
  display: flex;
  flex-direction: column;
  height: 70%;
  margin-bottom: 20px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 30px;
  row-gap: 1rem;

  @media (max-width: 1240px) {
    padding: 20px;
  }

  @media(max-width: 980px) {
    padding: unset;
    background-color: #FFFFFF;
    border-radius: unset;
    height: 100%;
  }

  .chat-receiver-name {
    display: flex;
    margin: 0px 25px;
    font-size: 12px;
    font-weight: 300;
  }

  .chat-datestamp-container {
    position: relative;
    margin-bottom: 1rem;

    .horizontal-divider {
      width: 50%;
      opacity: 0.5;
    }

    .chat-datestamp {
      text-align: center;
      color: #5200FF;
      font-size: 14px;
      display: flex;
      align-self: center;
      font-weight: 400;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background-color: #FFFFFF;
      padding: 0 20px;
    }
  }
  
  .chat-msg-container {
    display: flex;
    flex-direction: column;

    &:hover .edit-selected-button {
      display: flex;
      align-items: center;
      position: relative;
    }

    &:hover .chat-msg-body {
      margin-bottom: 0;
    }

    @media (max-width: 420px) {
      flex-direction: column;
    }

    .edit-selected-button {
      display: none;
      height: 10px;

      .edit-icon {

      }

      &.receiver {
        margin: 0px auto 0px 20px;

        @media (max-width: 420px) {
          margin: 0 auto 0 20px; 
          font-size: 10px;
        }
      }

      &.sender {
        margin: 0px 20px 0px auto;

        @media (max-width: 420px) {
          margin: 0 20px 0 auto;
          font-size: 10px;
        }
      }
    }
    
    .chat-msg-body {
      display: flex;
      white-space: pre-wrap;
      margin-bottom: 10px;

      .chat-timestamp {
        margin: 10px auto;
        text-align: center;
        color: #5200FF;
        font-size: 12px;
        display: flex;
        align-self: center;

        &.receiver {
          margin: 10px auto 10px 0;
  
          @media (max-width: 420px) {
            margin: 0 auto 0 20px; 
            font-size: 10px;
          }
        }
  
        &.sender {
          margin: 10px 0 10px auto;
  
          @media (max-width: 420px) {
            margin: 0 20px 0 auto;
            font-size: 10px;
          }
        }
      }

      .chat-attachment {
        max-width: 400px;
        width: 400px;
        padding: 10px 20px;
        width: fit-content;
        border-radius: 6px;
        line-height: 15px;

        .size {
          margin-left: 10px;
        }

        @media (max-width: 980px) {
          max-width: 320px;
          width: 320px;
        }

        @media (max-width: 800px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 700px) {
          max-width: 240px;
          width: 240px;
        }

        @media (max-width: 600px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 420px) {
          max-width: 240px;
          width: 240px;
          font-size: 14px;
        }

        @media (max-width: 360px) {
          max-width: 220px;
          width: 220px;
        }

        .download-link {
          text-decoration: underline;
          font-weight: 900;
        }

        &.receiver {
          background-color: #CAE3DD; 
          margin: 7px 10px 7px 20px;
          border-radius: 20px 20px 20px 0px;

          @media (max-width: 420px) {
            margin: 7px 10px 0 20px;
          }
        }

        &.sender {
          background-color: #FFFFFF;
          margin: 7px 20px 7px 10px;
          border-radius: 20px 20px 0px 20px;
          order: 1;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 980px) {
            background-color: #F5F5F5;
          }

          @media (max-width: 420px) {
            order: 0;
            align-self: flex-end;
          }
        }
      }

      .chat-message {
        max-width: 400px;
        width: 400px;
        padding: 10px 20px;
        width: fit-content;
        border-radius: 6px;
        line-height: 15px;
        font-weight: 300;

        @media (max-width: 980px) {
          max-width: 320px;
          width: 320px
        }

        @media (max-width: 800px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 700px) {
          max-width: 240px;
          width: 240px;
        }

        @media (max-width: 600px) {
          max-width: 280px;
          width: 280px;
        }

        @media (max-width: 420px) {
          max-width: 240px;
          width: 240px;
          font-size: 14px;
        }

        @media (max-width: 360px) {
          max-width: 220px;
          width: 220px;
        }

        &.receiver {
          background-color: #EFEFF1; 
          margin: 7px 10px 7px 20px;
          border-radius: 10px;

          @media (max-width: 420px) {
            margin: 7px 10px 0 20px;
          }
        }

        &.sender {
          background-color: #A988FC;
          color: #FFFFFF;
          margin: 7px 20px 7px 10px;
          border-radius: 10px;
          order: 1;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 420px) {
            order: 0;
            align-self: flex-end;
            margin: 7px 20px 0 10px;
          }
        }
      }
    }
  }
}
.overlay {
  background-color: #FFFFFFF2;
  height: 100vh;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh)*100);
  width: 100vw;
  left: 0;
  top: 100%;
  position: fixed;
  z-index: 99;
  transition: all .3s ease-out;

  &.open {
    top: 0;
    background-color: #FFFFFF;
  }

  &.nav-mobile-overlay {
    color: #FFFFFF;
    background-color: #5200ff;
  }

  &.filter-overlay {
    z-index: 9999;
  }

  .overlay-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .overlay-controls {
      align-self: flex-end;
      background: transparent;
      z-index: 1;
      height: 0;

      .overlay-logo {
        position: absolute;
        left: 1rem;
        top: 1rem;
        width: 110px;

        &.nav-mobile-overlay {
          top: 15.5px;
        }
      }

      .overlay-close {
        color: #B5B5B5;
        font: 600 30px Inter, Helvetica, Arial, sans-serif;
        position: relative;
        background: transparent;
        right: 1rem;
        top: 1rem;

        .line {
          background-color: #FFF;
          border-radius: 4px;
          display: block;
          height: 2.5px;
          margin: 5px auto;
          transition: all 0.3s ease-in-out;
          width: 26px;

          &:nth-child(1) {
            transform: translateY(7px) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }
  
          &:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
          }
        }

        &.filter-overlay > .line {
          background-color: #000;
        }
      }
    }
  }
}
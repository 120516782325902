.additional-broadcast-buttons {
  display: flex;
  align-items: center;

  .additional-button {
    background: #E4E4E4;
    padding: 3px 10px;
    border-radius: 30px;
    color: #9F9F9F;
    display: flex;
    align-items: center;
    margin-right: 6px;
    font: 400 11px Krub;

    .icon {
      margin-right: 8px;
    }

    &:hover {
      filter: brightness(90%);
    }
  }
}
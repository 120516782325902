.search-dropdown-container {
  .input-container {
    padding: 5px 10px;
    border: 2px solid #000000;
    border-radius: 40px;
    display: flex;

    .search-dropdown-input {
      font: 400 16px Krub;
      border: none;
      outline: none;
      min-width: 290px;
      width: 100%;
    }

    .icon {
      width: 18px;
      margin-right: 10px;
    }
  }

  .dropdown-container {
    height: 0;
    width: 100%;
    position: relative;

    .search-dropdown-list {
      position: absolute;
      background: #FFFFFF;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      top: 5px;
      width: 100%;
      max-height: 200px;
      overflow: auto;
      border-radius: 0 0 5px 5px;
      z-index: 2;

      .no-results-found {
        padding: 10px;
        font: 400 14px Krub;
        display: flex;
        justify-content: center;
      }

      .search-item {
        .search-item-button {
          padding: 6px 10px;
          width: 100%;
          align-items: center;
          display: flex;
          font: 400 16px Krub;
          background: transparent;
          overflow: hidden;
          justify-content: center;

          &:hover {
            background: #EFEFEF;
          }
        }

        &.selected {
          .search-item-button {
            font-weight: 600;
          }
        }
      }
    }
  }

}
.tag-with-delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;

  padding: 5px 10px;
  box-sizing: border-box;

  background-color: #A988FC;
  border-radius: 100px;

  .tag-with-delete-text {
    color: #FFFFFF;
    font-size: 12px;
  }

  .tag-button {
    background-color: transparent;
    height: 20px;

    .tag-img {
      filter: brightness(100);
      height: 20px;
    }
  }
}
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  background-color: #5200FF;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 9999;

  &.absolute {
    height: 100%;
    min-height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    position: unset;
  }

  &.table-size {
    height: 30px;
  }

  .dot-bricks-text {
    color: #fced65;
    margin-bottom: 20px;

    &.absolute {
      color: #5200ff;
    }
  }

  .dot-bricks {
    position: relative;
    top: 8px;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fced65;
    color: #fced65;
    box-shadow: 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65;
    animation: dot-bricks 2s infinite ease;

    &.absolute {
      background-color: #5200ff;
      color: #5200ff;
      box-shadow: 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff;
      animation: dot-bricks-absolute 2s infinite ease;
    }
  }
}

@keyframes dot-bricks {
  0% {
    box-shadow: 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #fced65, 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #fced65, 9991px -16px 0 0 #fced65, 10007px 0 0 0 #fced65;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #fced65, 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #fced65, 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #fced65, 10007px -16px 0 0 #fced65, 9991px 0 0 0 #fced65;
  }
  50% {
    box-shadow: 10007px 0 0 0 #fced65, 10007px -16px 0 0 #fced65, 9991px -16px 0 0 #fced65;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #fced65, 10007px -16px 0 0 #fced65, 9991px -16px 0 0 #fced65;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65, 9991px -16px 0 0 #fced65;
  }
  75% {
    box-shadow: 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65, 10007px -16px 0 0 #fced65;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #fced65, 10007px 0 0 0 #fced65, 10007px -16px 0 0 #fced65;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65, 10007px -16px 0 0 #fced65;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #fced65, 9991px 0 0 0 #fced65, 10007px 0 0 0 #fced65;
  }
}


@keyframes dot-bricks-absolute {
  0% {
    box-shadow: 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #5200ff, 9991px -16px 0 0 #5200ff, 10007px 0 0 0 #5200ff;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #5200ff, 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #5200ff, 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff;
  }
  50% {
    box-shadow: 10007px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff, 9991px -16px 0 0 #5200ff;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff, 9991px -16px 0 0 #5200ff;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff, 9991px -16px 0 0 #5200ff;
  }
  75% {
    box-shadow: 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #5200ff, 10007px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff, 10007px -16px 0 0 #5200ff;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #5200ff, 9991px 0 0 0 #5200ff, 10007px 0 0 0 #5200ff;
  }
}
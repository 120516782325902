.contact-action-dashboard {
  height: 100%;
  position: relative;
  
  .contact-action-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;

    .action-nav-button {
      background-color: transparent;
      padding: 4px 20px;
      box-sizing: border-box;

      &.selected {
        font-weight: 600;
        border-radius: 100px;
        background-color: #EDEDED;
        transition: background-color ease-in-out 0.3s;
      }
    }
  }
}
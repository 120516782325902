.automation-action-card {
  background: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  align-items: center;
  height: 480px;
  border: 2px solid #000000;
  
  .action-header {
    margin: 30px 0;
    display: flex;
    align-items: center;

    .action-title {
      font: 600 18px Krub;
    }

    .action-number {
      margin-left: 20px;
      font: 500 18px Krub;
      color: #7A8784;
    }
  }

  .action-details {
    display: flex;
    flex-flow: column;
    margin: 0 50px;

    .detail-container {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      .label {
        font: 600 18px Krub;
        flex: 1;
        text-align: center;
      }

      .detail {
        font: 400 18px Krub;
        flex: 2;

        .variable {
          background: #A988FC;
          color: #FFFFFF;
          padding: 4px 10px;
          border-radius: 50px;
          font-size: 18px;
          text-wrap: nowrap;
        }

        .numeric {
          background: #88A9FC;
          color: #FFFFFF;
          padding: 4px 10px;
          border-radius: 50px;
          font-size: 18px;
          text-wrap: nowrap;
        }
      }
    }
  }
}
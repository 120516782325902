.edit-automation {
  display: flex;
  flex-flow: column;
  box-shadow: 5px 4px 150px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin: 10px 0 30px 0;

  .automation-title {
    font: 600 20px Krub;
  }

  .edit-automation-input-container {
    margin-top: 25px;
  }

  .edit-automation-actions-container {
    margin-top: 70px;
  }

  .close-button {
    align-self: flex-end;
    font: 500 18px Krub;
    border: 2px solid #000000;
    padding: 8px 20px;
    min-width: 150px;
    border-radius: 50px;
    margin: 40px 0;

    &:hover {
      background: #EFEFEF;
    }
  }
}
.absolute-modal-container-wrapper {
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;
  background-color: transparent;

  &.fixed {
    position: fixed;
    background-color: rgba(35, 31, 32, 0.45);
  }
  
  .modal-container {
    border: 2px solid #000000;
    background-color: #FFFFFF;
    padding: 10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    min-width: 400px;

    &.interaction-task-modal {
      padding: 1rem;
    }
  }
}
.broadcast-card-container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  background-color: transparent;
  border-bottom: 1px solid rgba(35, 31, 32, 0.15);
  padding: 1rem;
  box-sizing: border-box;

  .broadcast-name-container {
    width: 100%;
    text-align: left;
    
    .broadcast-name {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .broadcast-schedule-container {
    text-align: left;
    width: 100%;
    
    .broadcast-schedule {
      font-size: 12px;
      
    }
  }

  .broadcast-tags-container {
    display: flex;
    column-gap: 10px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
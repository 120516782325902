.hamburger {
  background-color: transparent;

  .line {
    background-color: #FFF;
    border-radius: 4px;
    display: block;
    height: 2.5px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    width: 22px;
  }

  &.open {
    .line:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      width: 26px;
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
      width: 26px;
    }
  }
}
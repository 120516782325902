.preview-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button-with-tooltip {
    margin-left: 5px;

    .remove-icon {
      transform: scale(1.25);
    }
  }
}
.team-member-search-modal {
  .work-rex-modal {
    background: #FFFFFF;

    .work-rex-modal-content {
      margin: 30px 40px;
      min-width: 420px;
      max-width: 420px;

      .modal-title {
        font: 500 18px Krub;
        margin-bottom: 20px;
      }
      
      .selected-team-members {
        display: flex;
        flex-flow: row wrap;
        margin: 10px 0;

        .selected-team-member {
          padding: 4px 15px;
          border-radius: 40px;
          margin: 5px;
          color: #FFFFFF;
          background: #A988FC;
          display: flex;
          align-items: center;

          .name {
            font: 400 15px Krub;
            margin-right: 6px;
          }

          .remove-button {
            background: transparent;
            position: relative;
            top: 2px;
            
            .remove-icon {
              width: 22px;

              &:hover {
                filter: brightness(0.8);
              }
            }
          }
        }
      }

      .confirm-button {
        font: 500 16px Krub;
        padding: 5px 0;
        width: 135px;
        border-radius: 50px;
        border: 2px solid #000000;
        background: #000000;
        color: #FFFFFF;
        text-align: center;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.broadcasts-dropdown-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  background-color: #FFFFFF;
  max-height: 235px;
  position: relative;

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #FFFFFF;

    .filter-icon {
      height: 25px;
      width: 25px;
    }
  }

  .rec-header-icon {
    transition: all 0.2s;

    &.open {
      transform: rotate(-180deg);
      transition: all 0.2s;
    }
  }

  .dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow: hidden;
    font-size: 12px;
    opacity: 1;
    position: absolute;
    z-index: 2;
    background: #FFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

    &.open {
      // overflow: unset;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-answer {
      padding: 2rem;
      box-sizing: border-box;
      width: 700px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .dropdown-content > span {
    display: inline-block;
  }
}
.automation-flow-unit {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 130px;
  width: fit-content;

  .flow-icon-container {
    margin-bottom: 15px;

    .flow-icon {
      width: 35px;
    }
  }

  .label {
    text-align: center;
    font: 400 15px Krub;
  }
}
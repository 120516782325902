.manager-dropdown-container {
  width: 100%;

  .manager-dropdown-selector {
    background: #F8F9D3;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    .manager-text {
      min-width: 160px;
      text-overflow: ellipsis;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      font: 400 16px Krub;
    }

    &.disabled {
      opacity: 0.6;
      
      &:hover {
        cursor: auto;
      }
    }
  }

  &.open {
    .manager-dropdown-selector {
      .dropdown-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .manager-dropdown-options {
    width: 100%;
    position: relative;
    
    .manager-dropdown-list {
      position: absolute;
      width: 100%;
      z-index: 1;
      background: #F7F5F5;
      max-height: 100px;
      overflow: auto;
      padding: 5px 0 10px 0;

      .option-item {
        display: flex;

        .option-selection {
          width: 100%;
          padding: 8px 0;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;

          .option {
            margin-right: 15px;
            width: 14px;
          }

          .option-text {
            width: 120px;
            text-align: left;
            font: 400 14px Krub;
          }
        }
      }
    }
  }
}
.manager-dashboard-page {
  margin: 80px 20px 20px;

  @media (max-width: 820px) {
    margin: 20px;
  }

  .title {
    font: 600 28px Krub;
    margin-bottom: 40px;
  }
}
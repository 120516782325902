.engagement-component  {
  display: flex;
  align-items: center;

  &.title {
    justify-content: center;
    text-align: center;
  }

  &.description {
    text-align: left;
  }

  &.opportunity-score {
    justify-content: center;
    position: relative;
  }

  &.action-button {
    justify-content: center;
    position: relative;
  }

  &.edit-button {
    justify-content: center;
  }
}
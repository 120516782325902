.new-broadcast-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 2rem 1rem;
  height: 100%;

  &.confirm-broadcast {
    padding: 0 1rem 1rem;
  }

  .broadcast-modal-body {
    .modal-title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;

      &.confirm-broadcast {
        text-align: center;
        font-weight: 500;
      }
    }
  }

  .modal-button-container {
    display: flex;
    justify-content: center;
    column-gap: 1rem;

    &.confirm-broadcast {
      justify-content: flex-end;
    }
  }
}
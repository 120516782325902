.onboarding-button {
  width: 90px;
  border-radius: 50px;
  text-align: center;
  font: 600 12px Krub;
  padding: 3px 10px;
  background: #A988FC;
  color: #FFFFFF;
  border: 2px solid #A988FC;
  box-shadow: inset 0px -1.95349px 0px rgba(0, 0, 0, 0.25);

  &.sent {
    background: #D9D9D9;
    color: #231F20;
    border: 2px solid #000000;
    box-shadow: inset 0px -1.95349px 0px rgba(0, 0, 0, 0.25);
  }
}
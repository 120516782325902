.content-file-description {
  margin: 0 40px;
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  .file-description-input-container {
    display: flex;
    flex-flow: column;
    width: 100%;

    .file-description-label {
      font: 500 16px Krub;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .file-description-input {
      font: 500 16px Krub;
      padding: 10px 8px;
      max-width: 700px;
      min-height: 120px;
      border: 2px solid rgba(0, 0, 0, 0.25);
    }
  }

  .tag-editor {
    align-self: flex-start;
    display: flex;
    flex-flow: column;
    
    .tag-input-container {
      border: 2px solid rgba(0, 0, 0, 0.25);
      padding: 6px;
      display: flex;
      align-items: center;
      width: 280px;
      margin-bottom: 5px;

      .tag-icon {
        margin-right: 5px;
        width: 24px;
      }

      .tag-input {
        border: none;
        outline: none;
        font: 500 15px Krub;
        width: 100%;
      }
    }

    .error-text {
      display: flex;
      align-items: center;
      font: 600 15px Krub;
    }

    .tags {
      display: flex;
      flex-flow: row wrap;
      margin-top: 5px;

      .tag {
        background: #FEFFD8;
        padding: 10px 15px;
        margin: 5px 15px 5px 0;
        font: 600 16px Krub;
        display: flex;
        align-items: center;

        .remove-tag-button {
          width: 10px;
          background: transparent;
          margin-left: 10px;
          position: relative;
          top: 1px;
  
          .remove-icon {
            width: 10px;
          }

          &:hover {
            .remove-icon {
              transform: scale(1.2);
              filter: brightness(0.8);
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: 100%;

    .content-file-button {
      margin: 0 25px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
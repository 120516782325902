.social-media-post {
  margin-top: 10px;

  .caption-container {
    display: flex;
    flex-flow: column;
    margin-top: 10px;

    .caption-label {
      font: 400 18px Krub;
      margin-bottom: 10px;
    }

    .template-text-input-container {
      background: #FFFFFF;
      padding: 5px;

      .text {
        width: 100%;
        overflow: scroll;
        margin-right: 0;

        .template-text-input {
          padding: 5px;
          min-height: 180px;
          max-height: 180px;
        }
      }

      .word-count-and-editing-button {
        width: 0;
      }
    }
  }
}
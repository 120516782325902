.navigation-dropdown-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;

  background-color: #FFFFFF;
  max-height: 235px;
  position: relative;

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 12px;
    color: #000000;

    margin-bottom: 1px;
    padding: 6px 10px;
    box-sizing: border-box;
    
    background-color: #FFFFFF;
    
    transition: all 0.25s ease;
    z-index: 1;

    cursor: pointer;

    @media (max-width: 360px) {
      font-size: 12px;
      padding: 15px;
    }

    .navigation-view-item {
      display: flex;
      column-gap: 1rem;
      
      .messaging-navigation-icon {
        width: 14px;
      }
  
      .messaging-navigation-button-text {
        &.selected {
          font-weight: 700;
        }
      }
    }

    .filter-count {
      font-size: 12px;
      font-weight: 700;
      color: #9F9F9F;
    }
  }

  .rec-header-icon {
    transition: all 0.2s;
  }

  .open {
    transform: rotate(-180deg);
    transition: all 0.2s;
  }

  .dropdown-content {
    display: block;
    text-align: left;
    max-height: 0px;
    transition: all 0.25s ease;
    overflow: hidden;
    font-size: 12px;
    opacity: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;

    .dropdown-answer {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    @media (max-width: 370px) {
      font-size: 10px;
    }
  }

  .show-dropdown {
    padding: 10px 0;
  }

  .dropdown-content > span {
    display: inline-block;
  }
}
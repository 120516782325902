.edit-automation-input-container {
  padding: 5px 5px 5px 5px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;

  .text {
    width: 100%;
    margin-right: 60px;

    .description {
      font: 400 16px Krub;
      width: 100%;
      border: none;
      resize: none;
      padding: 10px 15px;
      min-height: 20.5px;
      border: 2px solid transparent;
      line-height: 35px;

      &[data-placeholder]:empty:before {
        content: attr(data-placeholder);
        color: #888888;
        font-style: italic;
      }


      &.editing {
        border-color: #5200FF;
      }

      .container {
        .variable {
          background: #A988FC;
          color: #FFFFFF;
          padding: 4px 10px;
          border-radius: 50px;
          font-size: 16px;
          text-wrap: nowrap;
        }

        .numeric {
          background: #88A9FC;
          color: #FFFFFF;
          padding: 4px 10px;
          border-radius: 50px;
          font-size: 16px;
          text-wrap: nowrap;
        }
      }
    }
  }

  .editing-button {
    margin-right: 20px;
    
    &:hover {
      transform: scale(1.2);
    }
  }
}
.verification-code-container {
  display: inline-block;
  position: relative;
  display: flex;
  margin: 1rem 0 0.5rem;

  .verification-digit {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 32px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    position: relative;
    margin: 0 1rem;
    border-radius: 3px;

    @media (max-width: 480px) {
      width: 24px;
      height: 32px;
      margin: 0 0.5rem;
      font-size: 24px;
    }

    .shadows {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border: 2px solid #9775E3;
    }
  }

  .code-input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
    margin: 0 1rem;
    padding: 0;

    @media (max-width: 480px) {
      margin: 0 0.5rem;
    }
  }
}
.channel-dropdown-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;

  box-sizing: border-box;
  padding: 0 10px;

  height: 25px;
  width: 100%;

  font-size: 12px;

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &.selected {
    font-weight: 700;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;

    @media (max-width: 420px) {
      width: 3rem;
    }

    .dropdown-option-selected {
      width: 16px;
    }
  }
  .channel-dropdown-button-text {
    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
}
.edit-account-page {
  padding: 50px 80px;
  border: 2px solid #000000;
  position: relative;
  top: -2px;

  .edit-account-container {
    display: flex;
    flex-flow: column;

    .edit-account-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .name-icon {
        margin-right: 25px;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #000000;
        border-radius: 50%;

        .icon-letter {
          font: 400 28px Krub;
        }
      }

      .name {
        font: 500 22px Krub;
      }
    }

    .edit-account-details {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;

      .details-column {
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        flex: 8;
        min-height: 380px;

        &.times {
          flex: 9;
        }

        .detail-row {
          display: flex;
          justify-content: space-between;
          width: 360px;

          .input-container {
            width: 360px;

            .label {
              color: #9F9F9F;
              font: 500 15px Krub;
              margin-bottom: 5px;
              margin-left: 2px;
            }

            .text-input {
              width: 330px;
            }
          }

          &.name-component {

            .input-container {
              width: 170px;

              .text-input {
                width: 140px;
              }
            }
          }
        }
      }
    }

    .confirm-button-container {
      display: flex;
      align-self: flex-end;
      margin-top: 30px;
      height: 40.5px;

      .confirm-button {
        background: #5200FF;
        border-radius: 30px;
        padding: 10px 20px;
        width: 160px;
        font: 500 16px Krub;
        color: #FFFFFF;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
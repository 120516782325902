.assistant-selection-section {
  .section-header {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    
    .section-title {
      font: 500 16px Krub;
      margin-right: 15px;
    }

    .integration-text {
      background: #D9D9D9;
      color: #231F20;
      padding: 2px 10px;
      font: 500 15px Krub;
      border-radius: 5px;

      &.integrated {
        background: #9BDF9B;
      }
    }
  }

  .section-description {
    font: 400 15px Krub;
    margin-bottom: 15px;
  }
}
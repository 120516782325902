.integration-card {
  width: 250px;
  height: 180px;
  border: 2px solid #000000;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px;
  margin: 10px 0;

  .ellipsis-container {
    align-self: flex-end;
    height: 30px;
  }

  .ellipsis-dropdown-container {
    align-self: flex-end;
    margin-right: 5px;
  }

  .integration-icon-container {
    width: 140px;
    height: 90px;
    display: flex;
    justify-content: center;

    .integration-icon {
      width: 200px;
    }
  }

  .request-button {
    background: #E9D647;
    color: #FFFFFF;
    border-radius: 50px;
    padding: 8px 10px;
    width: 130px;
    font: 500 15px Krub;
    margin-top: 15px;

    &.active {
      background: #67CE67;
      
      &:hover {
        opacity: 1;
        cursor: auto;
      }
    }
  }
}
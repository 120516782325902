.tasks-container {
  padding: 1rem;
  box-sizing: border-box;

  @media (max-width: 820px) {
    padding: 8px;
  }

  .tasks-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    column-gap: 1rem;

    .task-button {
      background-color: #FFFFFF;
      border-radius: 100px;
      border: 1px solid #000000;
      box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 4px 15px;
      box-sizing: border-box;
    }
  }
}
.campaign-source {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  padding: 30px 20px;
  background: #F5F5F5;
  border-radius: 38px;
  min-width: 280px;
  min-height: 420px;

  .source-tag {
    background: #A988FC;
    color: #FFFFFF;
    font: 500 16px Krub;
    width: 165px;
    text-align: center;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 30px;

    .rex-icon {
      width: 23px;
      margin-right: 10px;
    }
  }

  .source-details {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .source-title  {
      font: 500 20px Krub;
      margin-bottom: 30px;
    }
    
    .source-description {
      font: 400 15px Krub;
      margin-bottom: 15px;
      text-align: center;
      margin: 0 20px;
      max-width: 480px;
    }
  }

  .source-button {
    font: 500 16px Krub;
    width: 210px;
    text-align: center;
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 30px;
  }
  
  &.from-scratch {
    .source-button {
      background: #FFFFFF;
      color: #000000;
      border: 2px solid #000000;

      &:hover {
        background: #FAFAFA;
      }
    }
  }

  &.from-ai {

    .source-button {
      background: #5200FF;
      border: 2px solid #5200FF;
      color: #FFFFFF;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
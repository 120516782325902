.sms-preview {
  .preview-text {
    min-width: 460px;

    .preview-text-title {
      font: 400 16px Krub;
    }

    .preview-box {
      margin-top: 20px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 10px 15px;
      border-radius: 4px;
      display: flex;
      flex-flow: column;

      .preview-message {
        width: 370px;
        min-height: 180px;
        margin-bottom: 20px;
        background: #A988FC;
        color: #FFFFFF;
        margin-left: 30px;
        margin-top: 10px;
        border-radius: 10px;
        padding: 10px 15px;
        font: 500 14px Krub;
        white-space: normal;
        max-height: 240px;
        overflow: auto;
      }

      .preview-footer {
        width: calc(100% - 30px);
        padding: 15px;
        background: #EFEFF1;
        display: flex;
        align-items: center;
        height: 45px;
        justify-content: space-between;
        border-radius: 10px;

        .preview-footer-text {
          font: 400 15px Krub;
          color: #9F9F9F;
          align-self: flex-start;
        }

        .send-button {
          background: #5200FF;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
      }
    }
  }
}
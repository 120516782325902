$action-button-size: 50px;

.automation-action-cards-container {
  display: flex;
  align-items: center;
  margin-top: 50px;

  .action-button-container.left {
    position: absolute;
    width: 0;
    height: 50px;
  }

  .automation-action-button {
    background: #5200FF;
    max-width: $action-button-size;
    max-height: $action-button-size;
    min-width: $action-button-size;
    min-height: $action-button-size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .white-caret {
      width: calc($action-button-size - 20px);
      height: calc($action-button-size - 20px);
      position: relative;
    }

    &.right {
      left: calc(-1 * $action-button-size / 2) - 0px;

      .white-caret {
        left: 2px;
      }
    }

    &.left {
      left: calc(-1 * $action-button-size / 2);

      .white-caret {
        left: -2px;
      }
    }

    &:hover {
      transform: scale(1.1);
      filter: brightness(140%);
    }
  }

  .automation-action-cards {
    display: flex;
  }
}
.time-picker-modal-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  background-color: #FFFFFF;
  padding: 1rem;
  box-sizing: border-box;

  bottom: 40.5px;
  right: 0;
  position: absolute;

  border-radius: 10px;
  box-shadow: 0 0 30.5201px rgba(0,0,0,.1);

  .time-title-container {
    position: relative;
    display: flex;
    justify-content: center;

    .time-title {
      z-index: 1;
      font-size: 12px;
      padding: 0 1rem;
      box-sizing: border-box;
      color: #636363;
      letter-spacing: 1px;
      font-weight: 600;
      background-color: #FFFFFF;
    }

    .time-div {
      width: 90%;
      top: 50%;
      position: absolute;
      background-color: #9F9F9F;
    }
  }

  .time-selections {
    display: flex;
    flex-direction: column;
    row-gap: 6px;

    .time-selection {
      display: flex;
      column-gap: 10px;
    }
  }
}
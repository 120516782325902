.communication-hours {
  display: flex;
  flex-flow: row;

  .time-selection-container {
    display: flex;
    flex-flow: column;
    margin-right: 23px;

    .time-selection {
      display: flex;

      .am-toggle-button {
        height: 44.5px;
        background: #F0EFEF;
        font: 400 18px Krub;
        padding: 0 10px;
        margin-left: 7px;
      }
    }

    .range-container {
      display: flex;
      align-items: center;
      font: 400 16px Krub;
      margin-top: 5px;

      .range-checkbox {
        transform: scale(1.4);
      }

      .range-label {
        margin-left: 10px;
      }
    }
  }
}
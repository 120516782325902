.manager-page.iframe-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;

  iframe {
    border: none;
    width: 100% !important;
    opacity: 1;
  }

  &.loading iframe {
    opacity: 0;
  }
}

.assistant-dropdown-container {
  position: relative;

  .assistant-dropdown-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    font: 400 15px Krub;
    border-radius: 40px;
    padding: 6px 18px;
    background: #FFFFFF;
    border: 2px solid #000000;

    .text {
      margin-right: 20px;
    }

    &.disabled {
      opacity: 0.6;

      &:hover {
        cursor: auto;
      }
    }
    
    .downwards-caret-icon {
      width: 15px;
    }
  }

  &.open {
    .assistant-dropdown-button {
      .downwards-caret-icon {
        transform: rotate(180deg);
      }
    }
  }

  .assistant-dropdown-list-container {
    position: relative;
    width: 315px;
    margin: 10px 0;
    margin-left: 5px;
    border-radius: 10px;

    .assistant-dropdown-list {
      position: absolute;
      max-height: 200px;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
      width: 315px;
      overflow: auto;
      z-index: 5;
      border-radius: 10px;

      .assistant-dropdown-item {
        width: 100%;

        &.selected, &:hover {
          .assistant-dropdown-item-button {
            font-weight: 600;
          }
        }

        .assistant-dropdown-item-button {
          width: 100%;
          padding: 10px 8px;
          font: 400 14px Krub;
          color: #231F20;
          text-align: left;

          &:hover {
            background: #EFEFEF;
          }
        }
      }
    }
  }
}
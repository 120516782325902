.create-template-confirmation {
  .description {
    font: 500 18px Krub;
    text-align: center;
    max-width: 480px;
    margin: 10px 0;
  }

  .confirm-button {
    margin-top: 20px;
    background: #231F20;
    color: #FFFFFF;
    font: 400 16px Krub;
    border-radius: 50px;
    min-width: 140px;
    padding: 10px 5px;

    &:hover {
      opacity: 0.8;
    }
  }
}
.analysis-display {
  position: relative;
  top: -2px;

  .analysis-container {
    margin-bottom: 5rem;

    .insight-header {
      border: 2px solid #000000;
      padding: 1rem 2rem;
      box-sizing: border-box;
      margin-bottom: 3rem;
      background-color: #FFFFFF;

      .insight-title {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .image-container {
      position: relative;

      .placeholder-svg {
        width: 100%;
        border-radius: 40px;
        box-shadow: 6.556px 5.245px 19.681px 19.668px rgba(0, 0, 0, 0.10);
      }

      .cover-image {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 40px;
  
        &:hover {
          backdrop-filter: blur(2px);
          background-color: rgba(159, 159, 159, 0.50);
        }
      }
    }
  }
}
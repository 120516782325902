.chat-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  padding: 5px 20px;
  box-sizing: border-box;
  background-color: #FFFFFF;

  @media (max-width: 480px) {
    bottom: 60px;
    &.hidden {
      display: none;
    }
  }
  
  .chat-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    background-color: #F4F5F5;
    height: 100px;
    width: 100%;
    padding: 9px 6px;
    box-sizing: border-box;

    @media (max-width: 980px) {
      width: 90%;
      padding: unset;
    }

    .attachment-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: #55a08d;
      color: #FFFFFF;
      font-size: 34px;
      height: 35px;
      width: 35px;
      margin: 0px 10px;
      padding: 0px 10px 3px 9px;

      @media (max-width: 980px) {
        height: 32px;
        width: 32px;
        font-size: 30px;
        padding: 0 10px;
      }

      @media (max-width: 800px) {
        height: 30px;
        width: 30px;
        font-size: 28px;
        margin: 0 5px 0px 10px;
      }

      .add-attachment {
        margin-bottom: 2px;
        margin-left: 1px;
      }

      input {
        display: none;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }

    .emoji-button {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      background-color: transparent;
      margin: 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 980px) {
        height: 35px;
        width: 35px;
      }

      @media (max-width: 800px) {
        height: 25px;
        width: 25px;
      }

      .emoji-icon {
        height: 35px;
        width: 35px;

        @media (max-width: 980px) {
          height: 30px;
          width: 30px;
        }

        @media (max-width: 800px) {
          height: 25px;
          width: 25px;
        }
      }

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
        background-color: #F3FFFD;
      }
    }

    .emoji-picker-container {
      width: 0;
      height: 0;

      .emoji-picker-react {
        width: 250px;
        display: block;
        position: relative;
        top: -330px;
        left: -209px;
        height: 280px;
        overflow-y: scroll;
      }

      .triangle {
        width: 0;
        height: 0;
        position: relative;
        top: -321px;
        left: 20px;
        border: 10px solid transparent;
        border-bottom: 0;
        border-top: 15px solid #FFFFFF;
        transform: scaleY(-1);
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .attachments-container {
        height: 0;
        position: absolute;
      
        .attachments {
          display: flex;
          flex-direction: column-reverse;
          height: auto;
          position: relative;
          bottom: 25px;
        }
      }

      .chat-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 10px;
        box-sizing: border-box;
        background-color: transparent;
        resize: none;
  
        &::-webkit-scrollbar {
          display: none;
        }
      }
  
      .chat-input-content {
        height: 100%;
      }

      .input-menu-controls {
        display: flex;
        align-items: center;
        height: 25px;
        box-sizing: border-box;
        padding: 0 10px;
        column-gap: 1rem;
      }
    }

    .send-button {
      margin: 0 5px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }

      &.disable-chat {
        cursor: default;
        pointer-events: none;
        filter: unset;
      }

      .send-message-icon {
        width: 30px;
        height: 30px;

        @media (max-width: 480px) {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}
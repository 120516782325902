.tasks-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-top: 1rem;

  .tasks-list-status {
    .tasks-status-subtitle {
      font-size: 12px;
      color: #5200FF;
    }
  }
}
.campaign-text-input-container {
  display: flex;
  margin-right: 18px;

  &.offer-input-container {
    margin-right: 0;
  }

  .campaign-text-input-container-border {
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    width: 100%;
    
    .campaign-text-input {
      width: 100%;
      border: none;
      outline: none;
      resize: none;
      font: 300 16px Krub;
      height: 18px;
      max-height: 60px;
      background: #FFFFFF;
    }
  }
}
.activate-assistant-modal {
  .work-rex-modal {
    background: #FFFFFF;

    .work-rex-modal-content {
      margin: 30px 40px;

      .modal-title {
        font: 500 18px Krub;
        margin-bottom: 30px;
      }

      .close-button {
        font: 500 16px Krub;
        padding: 5px 0;
        width: 135px;
        border-radius: 50px;
        border: 2px solid #000000;
        background: #000000;
        color: #FFFFFF;
        text-align: center;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
.overflow-container {
  border: solid 2px #000000;
  position: relative;
  top: -2px;
}

.manager-table-container {
  overflow-x: auto;
  display: flex;
  flex-flow: column;

  .table-header {
    text-align: center;
    position: sticky;
    top: 0;
    box-shadow: inset 0 -2px 0 #000000;
    background: #FFFFFF;
    z-index: 2;

    .table-row {
      .table-header-cell {
        height: 50px;
        padding-left: 25px;
        text-align: left;

        .table-item {
          font: 600 15px Krub;
        }
      }
    }
  }
  
  .manager-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 620px;

    tbody {
      .table-row {
        padding: 10px;
        text-align: center;
        height: 50px;

        .table-cell {
          padding: 0 25px;
          min-width: 140px;
          text-align: left;
          
          .cell-text {
            font: 400 15px Krub;
            min-width: 110px;
            min-height: 20.5px;
            text-align: left;
          }

          .group-cell-text {
            font: 400 15px Krub;
            min-width: 110px;
            min-height: 20.5px;
            text-align: left;
            white-space: nowrap;
            max-width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          
          .text-input {
            text-align: center;
            width: calc(100% - 20px);
            font-size: 15px;
          }
        }
      }
    }
  }

  .no-rows-text {
    align-self: center;
    margin-top: 20px;
    text-align: center;
  }
}
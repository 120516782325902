.campaign-list-container {
  margin-top: 40px;
  display: flex;

  .campaigns {
    margin-left: 30px;
    margin-top: 10px;
    width: 100%;

    .campaigns-title {
      font: 500 18px Krub;
    }
  }
}
.automation-sidebar {
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 25px;
  padding: 15px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-top: 20px;
  height: calc(500px + 20vh);

  @media (max-height: 980px) {
    height: calc(500px + 16vh);
  }

  @media (max-height: 900px) {
    height: calc(450px + 16vh); 
  }

  @media (max-height: 800px) {
    height: calc(420px + 12vh); 
  }

  @media (max-height: 700px) {
    height: calc(410px + 10vh); 
  }

  @media (max-height: 680px) {
    height: 400px;
  }

  .add-button {
    min-width: 240px;
    padding: 10px 15px;
  }

  .divider {
    border-bottom: solid 2px #EFEFF1;
    min-width: 220px;
    height: 0;
    margin: 20px 0;
  }

  .search-input-container {
    .search-input {
      width: 190px;
    }
  }

  .library-type-filters {
    display: flex;
    flex-flow: column;
    margin: 25px 0;

    .library-type-selection {
      min-width: 200px;
      display: flex;
      align-items: center;
      margin: 20px 0;
      padding: 0 5px;

      &.selected {
        .button-text {
          font-weight: 600;
        }
      }

      .library-type-icon {
        width: 30px;
      }

      .button-text {
        margin-left: 30px;
        font: 400 16px Krub;
      }

      &:hover {
        .button-text {
          transform: scale(1.1);
        }
      }
    }
  }
}
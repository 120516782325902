.ellipsis-dropdown-container {
  .ellipsis-button {
    font: 700 16px Krub;
    letter-spacing: 2px;
    background: transparent;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    &.disabled {
      cursor: auto;
      
      &:hover {
        transform: scale(1);
      }
    }
  }

  .ellipsis-dropdown {
    position: absolute;
    height: 0;

    .dropdown-list {
      position: relative;
      background: #FFFFFF;
      filter: drop-shadow(0 2.6px 7.15px rgba(0, 0, 0, 0.25));

      @media (max-width: 480px) {
        left: -120px;
      }

      .dropdown-option .dropdown-selection {
        padding: 12px 15px;
        width: 160px;
        text-align: left;
        font: 400 14px Krub;

        &:hover {
          background: #ECECEC;
        }
      }
    }
  }
}
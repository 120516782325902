.template-navigation-tabs {
  display: flex;
  overflow: auto;
  padding-bottom: 20px;
  transform: rotateX(180deg);

  .template-navigation-tab-list {
    width: 100%;
    display: flex;
    transform: rotateX(180deg);


    .template-nav-tab {
      border: 2px solid #000000;
      background: #FFFFFF;
      border-radius: 10px 10px 0 0;
      min-width: 160px;
      font: 500 15px Krub;
      padding: 8px 30px;
      position: relative;
      white-space: nowrap;
      min-width: max-content;
      align-items: center;
      display: flex;

      &:hover {
        background: #EAEAEA;
      }

      &.selected {
        background: #FEFFD8;

        &:hover {
          background: #FEFFD8;
        }
      }

      &.section {
        .delete-icon {
          margin-left: 10px;
          width: 14px;

          &:hover {
            transform: scale(1.2);
          }
        }

        .text {
          margin-right: 15px;
        }
        
        .icon {
          position: absolute;
        }
      }

      &.add-section {
        font-weight: 400;
        color: #231F20;
        display: flex;
        justify-content: center;
        border-right: 2px solid #000000;

        .text {
          margin-right: 10px;
        }
      }
    }
  }
}
.template-type-navigation-tabs {
  display: flex;
  position: relative;
  top: -2px;
  width: 100%;

  .navigation-tab {
    border-right: none;
    flex: 1;

    &:last-child {
      border-right: 2px solid #000000;
    }
  }
}
.copy-text-button-container {
  display: flex;
  align-items: center;

  .label-text {
    font: 500 16px Krub;
    font-style: italic;
    color: #636363;
    margin-right: 12px;
  }

  .copy-text-button {
    background: transparent;

    .copy-icon {
      width: 36px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
.new-broadcast-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;

  .broadcast-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 20px;

    .broadcast-header-container {
      .broadcast-title {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
      }

      .new-broadcast-error-text {
        display: flex;
        height: 16px;
        color: red;
        font-size: 12px;
      }
    }

    .broadcast-detail-container {
      display: flex;
      flex-direction: column;
      max-height: 420px;
      overflow: auto;
      padding-right: 20px;

      @media (max-height: 740px) {
        max-height: 400px;
      }

      @media (max-height: 720px) {
        max-height: 380px;
      }

      @media (max-height: 700px) {
        max-height: 360px;
      }

      @media (max-height: 680px) {
        max-height: 340px;
      }


      .broadcast-field-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .broadcast-field-checkbox {
          display: flex;
          align-items: center;

          .checkbox-label {
            font: 400 16px Krub;
          }
        }

        &.no-margin {
          margin-bottom: 0;
        }

        .attachment {
          width: fit-content;
          margin-top: 10px;
        }

        .recipients-text {
          font: 500 16px Krub;
          margin-top: 3px;
        }

        .channel-selection-options {
          margin: 0;

          .channel-selection-list {
            margin: 0;
            gap: 6px;

            .channel-selection-button {
              width: 115px;
              font-size: 12px;
              padding: 6px 10px;

              .icon {
                width: 28px;
                height: 28px;
              }

              .button-text {
                margin-left: 8px;
              }
            }
          }
        }

        .view-recipients-button {
          font: 400 14px Krub;
          background: #FFFFFF;
          border: 1.5px solid #000000;
          color: #000000;
          padding: 8px 0px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          width: 110px;
          justify-content: center;
          outline: none;
          margin-top: 10px;
          height: 30px;

          &:hover {
            background: #ECECEC;
          }
        }
  
        .variable-tag-help-text {
          font-style: italic;
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.55);
          margin-top: 8px;
        }
  
        .broadcast-field-subtitle {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
        }
  
        .template-text-input-container {
          padding: 0 5px 5px 5px;

          .template-text-input {
            font-size: 14px;
            padding: 6px 10px;
            width: calc(100% - 20px);
            min-height: 100px;
          }

          .campaign-name-container {
            font-size: 14px;
            padding: 6px 10px;
            width: calc(100% - 20px);
            min-height: 100px;
          }
        }
    
        .broadcast-field-label {
          font-weight: 400;
          margin-bottom: 8px;
          font-size: 16px;
        }
  
        .new-broadcast-template-container {
          position: relative;
  
          .new-broadcast-template {
            resize: none;
            border: 2px solid rgba(0, 0, 0, 0.25);
            height: 200px;
          }
  
          .new-broadcast-wordCount {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
          }
        }
    
        .broadcast-input-box {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          border: 2px solid rgba(0, 0, 0, 0.25);
          padding: 1rem;
          box-sizing: border-box;
          max-height: 100px;
          overflow-y: scroll;
    
          .remove-receiver {
            margin-left: 5px;
            background-color: transparent;
          }
        }

        .message-channel-container {
          display: flex;
          gap: 1rem;
        }
      }
  
      .broadcast-field-button-container {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
      }
    }
  }

  .submit-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;

    .preview-button {
      font: 500 14px Krub;
      border: 1.5px solid #000000;
      color: #000000;
      padding: 5px 0px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      width: 140px;
      justify-content: center;
      outline: none;
      background: #5200FF;
      color: #FFFFFF;
      height: 30px;

      &.disabled {
        background: #231F20;
        cursor: auto;
        opacity: 0.6;

        &:hover {
          opacity: 0.6;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .schedule-button, .more-options-button {
      font: 500 14px Krub;
      background: #FFFFFF;
      border: 1.5px solid #000000;
      color: #000000;
      padding: 5px 0px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      width: 140px;
      justify-content: center;
      outline: none;
      height: 30px;

      .icon {
        margin-right: 7px;
        height: 18px;
      }

      &:hover {
        background: #ECECEC;
      }
    }
  }

  .submit-broadcast-container {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .submit-broadcast {
      border-radius: 30px;
      border: 1px solid #000;
      background: #5200FF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
      padding: 4px 15px;
      font-weight: 600;
    }
  }
}
.login-body-container {
  box-sizing: border-box;
  padding: 0 40px 40px;
  width: 100%;

  .login-proceed-button {
    height: 42px;
    color: #FFFFFF;
    background-color: #5200FF;
    width: 100%;
    border-radius: 3px;
    margin: 1rem 0;
  }

  .sign-in-options-divider {
    width: 100%;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    margin-top: 1rem;

    &::before {
      content: '';
      flex: 1 0 auto;
      margin: 0;
      border-bottom: 1px solid #c2c8d0;
      height: 0.5rem;
    }

    .sign-in-options-divider-text {
      text-align: center;
      flex: 0.2 0 auto;
      box-sizing: border-box;
    }
    
    &::after {
      content: '';
      flex: 1 0 auto;
      margin: 0;
      border-bottom: 1px solid #c2c8d0;
      height: 0.5rem;
    }
  }
}
.view-automation {
  display: flex;
  flex-flow: column;
  background: #FEFFD8;
  border: 2px solid #000000;
  margin-top: 30px;
  padding: 25px 40px;
  max-width: 1560px;
  
  .view-automation-header {
    margin-bottom: 35px;
  }

  .automation-details-container {
    display: flex;
    margin-bottom: 20px;

    .automation-details {
      display: flex;
      flex-flow: column;
      flex: 3;
      padding-right: 40px;

      @media (max-width: 1560px) {
        flex: 2;
      }
    }

    .automation-activity-container {
      flex: 1;

      @media (max-width: 1560px) {
        flex: 1;
      }

      .automation-buttons {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: center;

        .view-button {
          font: 500 18px Krub;
          background: #5200FF;
          border: 2px solid #000000;
          color: #FFFFFF;
          width: 160px;
          padding: 7px 0;
          border-radius: 30px;
          margin: 10px;

          &:hover {
            opacity: 0.8;
          }
        }

        .close-button {
          font: 500 18px Krub;
          background: #FFFFFF;
          border: 2px solid #000000;
          width: 160px;
          padding: 7px 0;
          border-radius: 30px;
          margin: 10px;

          &:hover {
            background: #EFEFEF;
          }
        }
      }
    }
  }
}
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  min-height: calc(100vh);
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  box-sizing: border-box;

  background-color: #5200FF;

  @media (max-width: 820px) {
    min-height: calc(100vh - 60px);
    min-height: calc(var(--vh, 1vh) * 100 - 60px);
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
  }

  @media (max-width: 480px) {
    background-color: #FFFFFF;
  }
  
  @media (max-width: 480px) {
    padding-top: unset;
  }

  .login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 12px 40px rgba(0,0,0,0.12);
    border-radius: 5px;

    background-color: #FFFFFF;
    width: 600px;

    @media (max-width: 1440px) {
      width: 520px;
    }
  
    @media (max-width: 1080px) {
      width: 480px;
    }
  
    @media (max-width: 720px) {
      width: 440px;
    }
  
    @media (max-width: 600px) {
      width: 400px;
    }
  
    @media (max-width: 480px) {
      width: 420px;
      border-radius: 0px;
      box-shadow: none;
    }
  
    @media (max-width: 420px) {
      width: 360px;
    }
  
    @media (max-width: 360px) {
      width: 320px;
    }   
  }
}
.search-bar {
  position: relative;
  padding: 0 10px;
  box-sizing: border-box;

  &.broadcast-search, &.new-broadcast-template-search, &.new-broadcast, &.no-padding {
    padding: 0;
  }

  &.max-width {
    width: 100%;
  }
  
  &.group-search .search-input {
    border-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .search-input {
    border-radius: 29px;
    border: 2px solid #5200FF;
    background: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 25px 5px 10px;
  }

  .search-icon-button {
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 12%;

    &.new-broadcast {
      top: 12%;
      right: 10px;
    }

    &.new-broadcast-template-search {
      top: 14%;
    }

    .search-icon {
      width: 20px;
    }
  }
}
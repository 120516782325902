.automation-tabs {
  display: flex;
  width: 100%;
  position: relative;
  top: -2px;
  
  .navigation-tab {
    flex: 1;
    border-right: none;

    &:last-child {
      border-right: 2px solid #000000;
    }
  }
}
.sort-dashboard {
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #231F20;

  .sort-header {
    background-color: #F0EFEF;
    box-sizing: border-box;
    padding: 5px 10px;

    border-bottom: 1px solid #231F20;
    border-radius: 5px;

    .sort-header-text {
      font-weight: 600;
    }
  }

  .sort-option-container {
    border-radius: 0px 0px 5px 5px;
  }
}
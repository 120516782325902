.work-rex-modal-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;
  background-color: rgba(0, 0, 0, 0.3);

  .work-rex-modal {
    background: #FEFFD8;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: 2px solid #000000;
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin: 20px;
    max-width: 600px;

    @media (max-width: 660px) {
      max-width: 460px;
    }

    .close-modal-button {
      background: transparent;
      align-self: flex-end;
      width: 20px;
      height: 10px;
      font: 400 36px Krub;
      color: #231F20;
      position: relative;
      top: -20px;
      right: -8px;

      &:hover {
        transform: scale(1.2);
        top: -24px;
        opacity: 0.6;
      }
    }
  }

  .work-rex-modal-content {
    margin: 25px 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
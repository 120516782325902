.groups-dropdown-container {
  .groups-dropdown-selector {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.disabled {
      cursor: auto;
    }

    .button-text {
      width: 90px;
      border-radius: 50px;
      text-align: center;
      font: 600 12px Krub;
      padding: 3px 10px;

      &.user-access {
        background: #FFFFFF;
        color: #231F20;
        border: 2px solid #000000;
      }

      &.active-button {
        background: #67CE67;
        border: 2px solid #67CE67;
        color: #FFFFFF;
      }

      &.inactive-button, &.opted-out {
        background: #FFFFFF;
        color: #231F20;
        border: 2px solid #000000;
      }

      &.delete {
        background: #d10909;
        color: #FFFFFF;
        border: 2px solid #d10909;
      }

      &.subscribed {
        background: #5200FF;
        color: #FFFFFF;
        border: 2px solid #5200FF;
      }

      &.undeliverable {
        background: #D9D9D9;
        color: #231F20;
        border: 2px solid #000000;
      }
    }

    .dropdown-arrow {
      width: 12px;
    }
  }

  &.open {
    .groups-dropdown-selector {
      .dropdown-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .groups-dropdown-options {
    width: 100%;
    position: relative;
    
    .groups-dropdown-list {
      position: absolute;
      width: 100%;
      z-index: 1;
      background: #FFFFFF;
      max-height: 190px;
      overflow: auto;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

      .option-item {
        display: flex;

        .option-selection {
          width: 100%;
          padding: 8px 12px;
          box-sizing: border-box;
          background: transparent;
          display: flex;
          justify-content: left;
          align-items: center;

          .option-text {
            text-align: left;
            font: 400 12px Krub;
          }

          &.selected {
            .option-text {
              font: 600 12px Krub;
            }
          }

          &:hover {
            background: #ECECEC;

            .option-text {
              font: 600 12px Krub;
            }
          } 
        }
      }
    }
  }
}
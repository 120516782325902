.attachment {
  background: #D4D4D4;
  padding: 8px 8px;
  display: flex;

  .attachment-name {
    font: 500 16px Krub;
    max-width: 280px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .delete-button-container {
    width: 0;
    height: 0;
  }

  .delete-button {
    background: #000000;
    color: #FFFFFF;
    font: 400 18px Krub;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -15px;
    left: 0px;

    &:hover {
      background: #4A4A4A;
    }

    &.disabled {
      &:hover {
        background: #000000;
        cursor: auto;
      }
    }
  }
}
.search-placeholder {
  color: rgba(0, 0, 0, 0.45);
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  font-size: 12px;

  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px 15px;
  row-gap: 5px;

  .search-placeholder-title {

  }
  
  .placeholder-list-item {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    .no-search-placeholder-icon {
      width: 10px;
    }

    .placeholder-list-text {

    }
  }
}
.content-file-page {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 40px 40px;
  margin: 10px auto 30px auto;
  max-width: 1560px;

  .file-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .title {
      .title-input {
        border: none;
        font: 600 20px Krub;
        padding: 6px 8px;
      }
    }

    .buttons {
      align-self: flex;
      display: flex;
      align-items: center;

      .back-button {
        align-self: flex-end;
        font: 600 16px Krub;
        padding: 6px 0;
        width: 180px;
        border-radius: 50px;
        border: 2px solid #000000;
  
        &:hover {
          background: #EFEFEF;
        }
      }

      .publish-button {
        align-self: flex-end;
        font: 600 16px Krub;
        padding: 6px 0;
        width: 200px;
        border-radius: 50px;
        background: #5200FF;
        border: 2px solid #000000;
        color: #FFFFFF;
        margin-left: 20px;
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .file-metadata {
    .file-type {
      font: 500 18px Krub;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
  
      .label {
        display: flex;
        background: #FEFFD8;
        width: 100px;
        padding: 6px 0;
        text-align: center;
        justify-content: center;
      }
  
      .type {
        display: flex;
        margin-left: 15px;
        padding: 6px 0;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .file-details {
    display: flex;
  }
}
.task-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .task-header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    box-sizing: border-box;

    .task-button {
      background-color: #FFFFFF;
      border-radius: 100px;
      border: 1px solid #000000;
      box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 4px 15px;
      box-sizing: border-box;
    }
  }

  .archive-task-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    box-sizing: border-box;

    .archive-text {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 2rem;
    }

    .confirm-archive {
      border-radius: 30px;
      border: 2px solid #231F20;
      background: #231F20;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
      color: #FFFFFF;
      padding: 5px 20px;
      box-sizing: border-box;
    }
  }
}
.create-campaign-display {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px 40px;
  margin: 0 auto 30px auto;
  max-width: 1560px;
  border-radius: 30px;
  margin-top: 10px;
  z-index: 3;
  position: relative;

  .campaign-inputs {
    max-height: 480px;
    overflow: auto;
    z-index: 5;
    margin-bottom: 10px;
  }

  .offer-buttons {
    display: flex;

    .plus-button {
      margin-right: 20px;
    }

    .view-offers-button {
      font: 400 16px Krub;
      background: #FEFFD8;
      border: 1.5px solid #000000;
      color: #000000;
      padding: 8px 0px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      width: 200px;
      justify-content: center;
      outline: none;

      &:hover {
        filter: brightness(95%);
      }
    }
    
    
  }
  .title {
    font: 500 18px Krub;
    margin-right: 15px;
    margin-bottom: 25px;
  }

  .campaign-input-section {
    margin-bottom: 25px;
  }

  .create-campaign-buttons {
    display: flex;
    align-self: flex-end;

    .cancel-button {
      font: 500 15px Krub;
      width: 140px;
      text-align: center;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      border-radius: 30px;
      background: #FFFFFF;
      color: #000000;
      border: 2px solid #000000;
      margin-right: 20px;

      &:hover {
        background: #FAFAFA;
      }
    }

    .continue-button {
      font: 500 15px Krub;
      width: 170px;
      text-align: center;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      border-radius: 30px;
      background: #5200FF;
      border: 2px solid #000000;
      color: #FFFFFF;

      &.disabled {
        opacity: 0.6;
        cursor: auto;

        &:hover {
          opacity: 0.6;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .channel-selection-options {
    margin-top: 0;
    z-index: 2;

    .channel-selection-button {
      width: 140px;
      padding: 8px 10px;

      .icon {
        width: 30px;
        height: 30px;
      }

      .button-text {
        font: 500 13px Krub;
      }

      .hover-tooltip-container {
        .hover-tooltip {
          position: absolute;
          width: max-content;
          padding: 10px 15px;
          background: #EAE0FF;
          font: 500 12px Krub;
          top: -65px;
          left: -10px;
          border-radius: 4px;
        }
      }
    }
  }
}
.task-card {
  display: flex;
  width: 440px;

  @media (max-width: 1260px) {
    width: 360px;
  }

  @media (max-width: 980px) {
    width: 280px;
  }

  @media (max-width: 800px) {
    width: 400px;
  }

  @media (max-width: 480px) {
    width: 360px;
  }

  @media (max-width: 420px) {
    width: 320px;
  }

  @media (max-width: 360px) {
    width: 280px;
  }

  .complete-button {
    align-self: flex-start;

    .complete-icon {
      width: 25px;
      height: 25px;
    }

    .incomplete-icon {
      width: 20px;
      height: 20px;
    }
  }

  .task-details {
    display: flex;
    flex-flow: column;
    margin-left: 10px;

    .task-name {
      font: 500 18px Krub;
      margin-bottom: 5px;
      color: #231F20;
    }

    .assigned-date {
      color: #000000;
      opacity: 0.7;
      font: 400 16px Krub;
      margin-bottom: 2px;
    }

    .due-date {
      color: #000000;
      opacity: 0.7;
      font: 400 16px Krub;
      margin-bottom: 10px;
    }

    .tagged-name {
      background: #A988FC;
      width: fit-content;
      padding: 5px 15px;
      font: 500 16px Krub;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
}
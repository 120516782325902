.manager-recipients-page {
  .page-title {
    font: 600 28px Krub;
    margin-bottom: 10px;
  }

  .recipients-table {
    display: flex;
    flex-flow: column;

    .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 25px 0;

      .left-section {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        .active-recipients {
          font: 400 18px Krub;
          margin-right: 20px;
          width: 300px;

          .bold {
            font-weight: 800;
          }
        }
      }

      .right-section {
        display: flex;
        align-items: center;

        .buttons {
          display: flex;

          .reset-button {
            background: #FFFFFF;
            color: #000000;
            border-radius: 40px;
            font: 500 15px Krub;
            padding: 4px 0;
            width: 100px;
            border: 2px solid #000000;
            margin-right: 20px;
    
            &:hover {
              background: #f6f4f4;
            }
          }
    
          .confirm-button {
            background: #000000;
            color: #FFFFFF;
            border-radius: 40px;
            font: 500 15px Krub;
            padding: 4px 0;
            width: 100px;
            border: 2px solid #000000;
            margin-right: 20px;
    
            &:hover {
              opacity: 0.8;
            }
          }
        }

        .recipients-page-toggle {
          margin-right: 20px;
        }

        .import-recipients-button {
          background: #000000;
          color: #FFFFFF;
          font: 500 15px Krub;
          padding: 4px 10px;
          border-radius: 30px;
          width: 180px;
          align-self: flex-end;
          border: 2px solid #000000;
    
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }

    .customers-table-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;

      .view-more-customers-button {
        align-self: flex-start;
        font: 400 15px Krub;
        border: 2px solid #000000;
        border-radius: 50px;
        padding: 2px 10px;
      }

      .loading-container {
        height: 30px;
        width: 165px;
      }
    }

    .add-recipient-button {
      font: 400 15px Krub;
      align-self: flex-start;
      margin: 15px 0 15px 25px;

      &:hover {
        opacity: 0.8;
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    .manager-table-container {
      height: calc(100vh - 253px);

      .manager-table {
        min-width: 820px;
      }
    }

    .flatfile_iframe-wrapper {
      .flatfile_iFrameContainer {
        height: 80% !important;
        max-height: 750px;
      }
    }
  }
}
.broadcast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;

  padding: 1rem 10px 0;
  box-sizing: border-box;
  height: 40px;

  .search-icon-button {
    background: transparent;
    height: 25px;

    .search-icon {
      height: 25px;
    }
  }

  .broadcast-count {
    font-size: 12px;
    white-space: nowrap;
    margin-left: 10px;
  }
}
.messaging-body-container {
  display: flex;

  border: 2px solid #000000;
  background: #FFFFFF;

  width: 100%;
  height: calc(100vh - 182px);
  box-sizing: border-box;

  top: -2px;
  position: relative;

  @media (max-width: 820px) {
    height: calc(100vh - 182px)
  }

  .messaging-body-navigation-container {
    flex: 1;
    max-width: 257.5px;
    width: 257.5px;
    border-right: 1px solid #000000;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .messaging-body-list-container {
    flex: 1;
    max-width: 257.5px;
    width: 257.5px;
    border-right: 1px solid #000000;
  }

  .messaging-body-detail-container {
    flex: 2;
  }
}
.integrations-sidebar {
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 25px;
  padding: 15px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-top: 5px;
  min-width: 240px;

  height: calc(500px + 20vh);

  @media (max-height: 980px) {
    height: calc(500px + 16vh);
  }

  @media (max-height: 900px) {
    height: calc(450px + 16vh); 
  }

  @media (max-height: 800px) {
    height: calc(350px + 10vh); 
  }

  @media (max-height: 700px) {
    height: calc(340px + 10vh); 
  }

  @media (max-height: 680px) {
    height: 380px;
  }

  .divider {
    border-bottom: solid 2px #EFEFF1;
    min-width: 200px;
    height: 0;
    margin: 20px 0;
  }

  .filter-button-list {
    margin-top: 10px;
    min-width: 220px;

    .filter-button {
      .icon {
        width: 14px;
      }

      .button-text {
        font-size: 15px;
        margin-left: 20px;
      }
    }
  }
}
.ellipsis-dropdown-container.automation-dropdown {
  align-self: flex-end;
  position: relative;
  right: -5px;
  top: -1px;

  .ellipsis-button {
    .automation-dropdown-icon {
      width: 25px;
    }
  }
}
.double-toggle-button-container {
  display: flex;

  .double-toggle-button {
    background: #F0EFEF;
    width: 100px;
    padding: 5px 0;
    display: flex;
    align-items: center;

    &.left {
      border-radius: 4px 0 0 4px;
      justify-content: flex-end;
    }

    &.right {
      border-radius: 0 4px 4px 0;
      justify-content: flex-start;
    }

    .button-text {
      font: 400 14px Krub;
      background: transparent;
      color: #B3B3B3;
      border-radius: 4px;
      width: 70px;
      text-align: center;
      padding: 8px 12px; 
    }

    &.selected {
      .button-text {
        background: #FFFFFF;
        color: #231F20;
      }
    }
  }
}
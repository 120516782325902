.time-picker-container {
  display: flex;
  column-gap: 10px;

  border: 2px solid #BFBFBF;
  background: #FFFFFF;
  padding: 5px 10px;
  box-sizing: border-box;
  text-align: left;
  position: relative;;

  .time-picker-button {
    display: flex;
    align-items: center;
    column-gap: 5px;

    background-color: transparent;

    .time-display {
      width: 40px;
    }

    .rec-header-icon {
      transition: all 0.2s;
      width: 8px;

      &.open {
        transform: rotate(-180deg);
        transition: all 0.2s;
      }
    }
  }

  .am-pm-button {
    background-color: transparent;
    height: 24px;
    width: 24px;

    &:hover {
      background-color: #5200FF;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
}
.filter-input-text {
  resize: none;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 15px;
  gap: 5px;

  position: relative;

  background: #FFFFFF;
  border: 1px solid #F0EFEF;
  flex: 2;
}
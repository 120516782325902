.integrations-page {
  .integrations-header {
    width: 100%;
    border: solid 2px #000000;
    position: relative;
    top: -2px;

    .title {
      font: 500 16px Krub;
      padding: 15px 20px;
    }
  }

  .integrations {
    display: flex;
    margin-top: 20px;
  }
}
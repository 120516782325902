.template-section {
  display: flex;
  flex-flow: column;
  
  .title-template-input {
    position: relative;
    top: -2px;
  }

  .response-expectation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .template-attachment-button-container {
      margin-top: 5px;
    }
  }

  .section-message-template-input {
    margin: 28px 0;
    background: #F4F5F5;

    &.template-text-input-container {
      .text {
        .template-text-input {
          min-height: 120px;
          font-size: 14px;
        }

        .variable {
          font-size: 14px;
        }
      }
    }
  }
}
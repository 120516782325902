.button-with-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #9F9F9F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  img {
    width: 16px;
    height: 16px;
  }

  .tooltip-container {
    visibility: hidden;
    width: 0;
    position: relative;

    .tooltip {
      position: absolute;
      width: max-content;
      padding: 10px 15px;
      background: #EAE0FF;
      font: 500 13px Krub;
      top: -60px;
      left: -25px;
      border-radius: 5px;
    }
  }

  &:hover {
    background: #AFAFAF;

    .tooltip-container {
      visibility: visible;

      .tooltip {
        filter: brightness(100%);
      }
    }
  }
}
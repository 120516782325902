.filter-dashboard {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  .filter-list {
    row-gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .filter-control-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .new-filter-button {
      box-sizing: border-box;
  
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px 25px;
  
      width: 150px;
      position: relative;
  
      background: #F0EFEF;
      border: 1px solid #231F20;
      border-radius: 100px;
  
    }

    .filter-application-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      column-gap: 10px;

      .reset-filter-button {
        box-sizing: border-box;
  
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 25px;
    
        width: 80px;
        position: relative;
    
        background: #F0EFEF;
        border-radius: 30px;
      }

      .apply-filter-button {
        box-sizing: border-box;
  
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 7px 25px;
    
        width: 80px;
        position: relative;
    
        background: #FFFFFF;
        border: 2px solid #000000;
        border-radius: 30px;
      }
    }
  }
}
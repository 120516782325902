.automation-card {
  display: flex;
  flex-flow: column;
  padding: 15px 20px 15px 20px;
  max-width: 360px;
  min-width: 360px;
  height: 200px;
  border: 2px solid #000000;

  &.active {
    background: #FEFFD8;
  }

  &.available {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }

    .ellipsis-dropdown-container .ellipsis-button.disabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .card-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &.no-label {
      justify-content: flex-end;
    }

    .label {
      font: 400 12px Krub;
    }

    .ellipsis-dropdown-container {
      align-self: flex-end;
    }
  }

  .automation-title {
    font: 500 20px Krub;
    margin-bottom: 10px;
  }

  .automation-details {
    height: 140px;
  }

  .automation-description {
    align-self: flex-start;
    font: 400 14px Krub;
  }

  .automation-objective {
    margin-top: 10px;
    font: 600 15px Krub;
    padding: 5px 15px;
    border-radius: 5px;
    background: #EFEFF1;
    width: fit-content;
  }

  .automation-card-button {
    align-self: flex-end;
    width: 130px;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 5px 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font: 600 13px Krub;
    background: #FFFFFF;

    &.toggle {
      width: 100px;
      font: 600 16px Krub;
      padding: 18px 0px;
      border-radius: 50px;

      .icon {
        width: 18px;
      }
    }
  }

  &.requested {
    background: #EFEFF1;

    .automation-card-button {
      background: #FFFFFF;

      &:hover {
        opacity: 1;
        cursor: auto;
      }
    }
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-recommended {
      align-self: flex-end;
    }

    .recommendation-url {
      color: #5200FF;
      text-decoration: underline;
      font: 400 16px Krub;
      font-style: italic;
    }
  }

  &.module {
    height: 250px;

    .automation-details {
      height: 200px;
    }

    .automation-card-button {
      &.request {
        background: #5200FF;
        color: #FFFFFF;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
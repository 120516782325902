.content-file-button {
  width: 170px;
  color: #231F20;
  background: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  font: 500 15px Krub;
  border-radius: 3px;

  .button-icon {
    margin-right: 10px;
  }

  .button-text {
    margin-left: 10px;
  }

  &:hover {
    background: #DCCEFF;
  }
}

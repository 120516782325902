.campaign-recipients-display {
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px 40px;
  margin: 10px auto 30px auto;
  max-width: 1560px;
  border-radius: 30px;
  margin-top: 10px;

  .title {
    font: 500 18px Krub;
    margin-right: 15px;
  }

  .campaign-recipients {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .buttons {
    align-self: flex-end;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;

    .selection-number {
      margin-right: 20px;
      font: 400 16px Krub;
    }

    .broadcast-button {
      align-self: flex-end;
      font: 500 16px Krub;
      background: #5200FF;
      border: 2px solid #000000;
      color: #FFFFFF;
      padding: 10px 45px;
      border-radius: 50px;
      margin-left: 20px;

      &.disabled {
        background: #9F9F9F;

        &:hover {
          opacity: 1;
          cursor: auto;
        }
      }
  
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.task-detail-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .task-detail-body-container {
    display: flex;

    .task-icon-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 1rem;
      box-sizing: border-box;

      @media (max-width: 820px) {
        padding: 1rem 0 0 8px;
      }
  
      .task-type-icon {
        width: 25px;
        height: 25px;
      }
    }
  
    .task-detail-body {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      width: 100%;
      row-gap: 10px;
      padding: 1rem;
      box-sizing: border-box;
  
      .task-heading {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 14px;
        font-weight: 600;
  
        .task-due {
          font-size: 14px;
        }
  
        .task-label {
          font-weight: 500;
        }

        .edit-selected-button {
          display: flex;
          align-items: center;
          position: relative;
    
          .edit-icon {
    
          }
        }
      }
  
      .task-creation-date-container {
        .task-creation-date {
          color: rgba(0, 0, 0, 0.54);
        }
      }
  
      .task-assigned-labels {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }
  
  .task-history {
    border-top: 1px solid rgba(35, 31, 32, 0.15);
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-height: 50%;
    height: 50%;
    overflow-y: scroll;

    .task-history-title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
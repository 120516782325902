.recipients-view {
  background: #FFFFFF;
  border: 2px solid #000000;
  padding: 5px;
  flex: 1;
  margin-left: 20px;
  border-radius: 25px;
  height: 420px;
  display: flex;

  .empty-recipients {
    display: flex;
    justify-content: center;
    margin: 20px;
  }

  .recipients-table-container {
    border: none;

    .table-body {
      border: none;
    }
  }
}